import CountUp from "react-countup";

export const CountComponent = ({
  number,
  decimals,
  suffix,
  prefix,
  separator
}: {
  number: number;
  decimals: number;
  suffix?: string;
  prefix?: string;
  separator?: string;
}) => {
  return <CountUp separator={separator || ""} suffix={suffix || ""} prefix={prefix || ""} end={number} duration={0.5} decimals={decimals} />;
};
