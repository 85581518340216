import { Stack, Text, Image } from "@chakra-ui/react";
import { useWallet } from "@terra-money/wallet-provider";
import { useStakeInfo } from "components/state/global";
import { GREY_4, ORANGE_5, SHADOW_MAIN } from "constants/variables";
import { queryFinder } from "functions/queryFinder";
import Undelegation from "./Undelegation";

const MyUndelegations = () => {
  const [{ undelegations }] = useStakeInfo("delegatedInfo");
  const { network } = useWallet();

  return (
    <>
    {undelegations.length !== 0 ? 
      <Stack direction="column" gap="21px">
      <Text className="caption_1" color={ORANGE_5}>
        Undelegations
      </Text>
      {undelegations.map((undelegation, i) => 
      <Undelegation network={network} undelegation={undelegation} key={i}/>)}
    </Stack> : null}
    </>
  );
};

export default MyUndelegations;
