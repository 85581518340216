import { Flex, Text } from "@chakra-ui/react";

const Terms = () => {
  return (
    <Flex className="terms" direction='column' pt={{ base: '40px' }} pb={{ base: '40px' }}>
      <Text className="title_1">Terms of Use</Text>

      <Text className="title_3">1. Eligibility</Text>

      <Text>
        In order to use the Interface, you must satisfy the following eligibility requirements. You hereby represent and warrant, to and for the benefit of us and each of our officers, directors, supervisors, shareholders, members, 
        investors, employees, agents, service providers and affiliates that you satisfy all of the eligibility requirements as of each date that you make any use or receive any benefits of the Interface.
      </Text>

      <Text>
      1.1 you are of legal age in the jurisdiction in which you reside and you have legal capacity to enter into the Terms and be bound by them;
      </Text>

      <Text>
      1.2 if you accept the Terms on behalf of a legal entity, you must have the legal authority to accept the Terms on that entity’s behalf, in which case “you” as used herein (except as used in this paragraph) will mean that entity;
      </Text>

      <Text>
      1.3 (i) you are not a resident, citizen, national or agent of, or an entity organized, incorporated or doing business in, Belarus, Burundi, Crimea and Sevastopol, Cuba, Democratic Republic of Congo, Iran, Iraq, Libya, North Korea, Somalia, Sudan, Syria, Venezuela, Zimbabwe or any other country to which the United States, the United Kingdom, the Cayman Islands, the European Union or any of its member states or the United Nations or any of its member states (collectively, the “Major Jurisdictions”) embargoes goods or imposes similar sanctions (such embargoed or sanctioned territories, collectively, the “Restricted Territories”); (ii) you are not, and do not directly or indirectly own or control, and have not received any assets from, any blockchain address that is, listed on any sanctions list or equivalent maintained by any of the Major (such sanctions-listed persons, collectively, “Sanctions Lists Persons”); and (iii) you do not intend to transact in or with any Restricted Territories or Sanctions List Persons; and
      </Text>

      <Text>
      1.4 you are not a Restricted Person; and
      </Text>

      <Text>
      1.5 your use of the Interface is not prohibited by and does not otherwise violate or facilitate the violation of any applicable laws or regulations, or contribute to or facilitate any illegal activity.
      </Text>

      <Text className="title_3">
      2. Access to the Interface
      </Text>

      <Text>
      We reserve the right to disable access to the Interface at any time, with or without cause or good reason. Our grounds for terminating access to the Interface may include, but are not limited to, any breach of the Terms, including without limitation, if we, in our sole discretion, believe that you, at any time, fail to satisfy the eligibility requirements set forth in the Terms. Further, we reserve the right to limit or restrict access to the Interface by any person or entity, or within any geographic area or legal jurisdiction, at any time and in our sole discretion. We will not be liable to you for any losses or damages you may suffer as a result of or in connection with the Interface being inaccessible to you at any time or for any reason.
      </Text>

      <Text className="title_3">
      3. Proprietary Rights
      </Text>

      <Text>
      3.1 We own all intellectual property and other rights in the Interface and its contents, including, but not limited to, software, text, images, trademarks, service marks, copyrights, patents, and designs. Unless expressly authorized by us, you may not copy, modify, adapt, rent, license, sell, publish, distribute, or otherwise permit any third party to access or use the Interface or any of its contents. Accessing or using the Interface does not constitute a grant to you of any proprietary intellectual property or other rights in the Interface or its contents.
      </Text>


      <Text>
      3.2 You will retain ownership of all intellectual property and other rights in any information and materials you submit through the Interface. However, by uploading such information or materials, you grant us a worldwide, royalty-free, irrevocable license to use, copy, distribute, publish and send this data in any manner in accordance with applicable laws and regulations.
      </Text>

      <Text>
      3.3 You may choose to submit comments, bug reports, ideas or other feedback about the Interface, including, without limitation, about how to improve the Interface (collectively, “Feedback”). By submitting any Feedback, you agree that we are free to use such Feedback at our discretion and without additional compensation to you, and to disclose such Feedback to third parties (whether on a non-confidential basis, or otherwise). If necessary under applicable law, then you hereby grant us a perpetual, irrevocable, non-exclusive, transferable, worldwide license under all rights necessary for us to incorporate and use your Feedback for any purpose.
      </Text>

      <Text>
      3.4 If (i) you satisfy all of the eligibility requirements set forth in the Terms, and (ii) your access to and use of the Interface complies with the Terms, you hereby are granted a single, personal, limited license to access and use the Interface. This license is non-exclusive, non-transferable, and freely revocable by us at any time without notice or cause in our sole discretion. Use of the Interface for any purpose not expressly permitted by the Terms is strictly prohibited. Unlike the Interface, the Protocol is comprised entirely of open-source software running on the public Ethereum blockchain and is not our proprietary property.
      </Text>
    </Flex>
  )
};

export default Terms;