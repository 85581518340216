import { Link } from "react-router-dom";
import st_ftr from "./Footer.module.scss";

const Footer = () => {

    return (
      <footer id={st_ftr.footer}>
        <div className={st_ftr.container}>
          <div className={st_ftr.left}>
            <h3 className="title_4">©Terran One All Rights Reserved.</h3>
            <p className="body_2">
              2120 University Ave, <br />
              Berkeley, CA 94704, <br />
              USA
            </p>
          </div>
          <div className={st_ftr.right}>
            <Link to="/privacy" className={`body_3 ${st_ftr.links}`} target='_blank'>
              Privacy Policy
            </Link>
            <Link to="/terms" className={`body_3 ${st_ftr.links}`} target='_blank'>
              Terms of Use
            </Link>
          </div>
        </div>
      </footer>
    );
}

export default Footer;