import st_btn from "./Button.module.scss";

const ConnectButton = ({
  text,
  onClick,
  style,
}: {
  text: string;
  onClick: any;
  style: any;
}): JSX.Element => (
  <button style={style} onClick={onClick} className={`cta_2 ${st_btn.connect}`}>
    {text}
  </button>
);

export default ConnectButton;