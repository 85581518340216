import {
  Button,
  Grid,
  Stack,
  Text,
  Image,
} from "@chakra-ui/react";
import { useStakeInfo } from "components/state/global";
import TxModal from "components/Modal/TxModal";
import { Wanna } from "constants/enums";
import DelegationComponent from "./DelegationComponent";
import { useSetState } from "react-use";
import { GREY_1, GREY_4, ORANGE_5 } from "constants/variables";
import { DelegationState } from "constants/types";
import DelegationModal from "./Modal/DelegationModal";
import { useEffect, useRef, useState } from "react";

const MyDelegations = () => {
  const [{ delegations }] = useStakeInfo("delegatedInfo");
  const [from, setFrom] = useState<number>(0);
  const scrollRef = useRef<any>(null);

  const [delState, setDelState] = useSetState<DelegationState>({
    open: false,
    status: "active",
    wanna: Wanna.Stake,
    howMuch: undefined,
    validatorChosen: "",
    msgs: [],
  }); 

  const setManage = (validator: string) => {
    setDelState({
      validatorChosen: validator,
      open: true,
    });
  };

  const setPagination = (i: number) => {
    setFrom(i * 5);
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView();
    }
  }

  const incrementFrom = () => {
    const hasPagination = Math.ceil(delegations.length / 5) > 1;
    const hasMorePagination = from < delegations.length - 5;
    const hasScrollRef = scrollRef?.current != null;
    if (hasPagination && hasMorePagination && hasScrollRef) {
      setFrom(prev => prev + 5);
      scrollRef.current.scrollIntoView();
    }
  }

  const decrementFrom = () => {
    const isDecrementable = from >= 5;
    const hasScrollRef = scrollRef?.current != null;
    if (isDecrementable && hasScrollRef) {
      setFrom(prev => prev - 5)
      scrollRef.current.scrollIntoView();
    }
  }

  useEffect(() => {
    if (!delegations?.length) return;

    if (delegations.length === from) {
      setFrom((Math.floor(delegations.length / 5) - 1) * 5)
    }
  }, [delegations.length]);

  return (
    <>
      <TxModal msgs={delState.msgs} />
      <DelegationModal delState={delState} setDelState={setDelState}/>
      <Stack direction="column" gap="21px" ref={scrollRef}>
        <Text className="caption_1" color={ORANGE_5}>
          Delegations
        </Text>
        {delegations.slice(from, from + 5)
          .map((delegation: any, i: number) => (
            <DelegationComponent
              key={i}
              delegation={delegation}
              setManage={() => setManage(delegation.validatorAddress ?? "")}
            />
          ))}
      </Stack>
      <Grid placeItems="center" height="fit-content" width="100%">
          <Stack direction="row" minW="200px"
          gap="20px" alignItems="center" justifyContent="center">
            <Button 
            onClick={decrementFrom}
            colorScheme="whiteAlpha"
            border={`1px solid ${GREY_4}`}
            boxShadow="0px 5px 7px rgba(56, 56, 56, 0.04) !important"
            color={GREY_1}>
              <Image src="/images/svg/ui-assets/chevron_right.svg" 
              w="20px" h="20px" transform="rotate(180deg)"/>
            </Button>
            <Stack direction="row" gap="15px">
              {new Array(Math.ceil(delegations.length / 5)).fill(0).map((_, i) => {
                return <Text 
                key={i}
                className="title_4"
                cursor="pointer" 
                style={{ 
                  fontWeight: from / 5 === i ? 
                  "bold" : "normal" 
                }}
                onClick={() => setPagination(i)}>{i+1}</Text>
              })}
            </Stack>
            <Button
            onClick={incrementFrom}
            colorScheme="whiteAlpha"
            border={`1px solid ${GREY_4}`}
            boxShadow="0px 5px 7px rgba(56, 56, 56, 0.04) !important"
            color={GREY_1}>
              <Image src="/images/svg/ui-assets/chevron_right.svg" 
              w="20px" h="20px" rotate="180deg"/>
            </Button>
          </Stack>
      </Grid>
    </>
  );
};

export default MyDelegations;
