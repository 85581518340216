import st_pr from "../Profile.module.scss";

const Bio = ({ bio }: { bio: string }): JSX.Element => {
  return (
    <div className={st_pr.bio_container}>
      <h3 className={`body_2`}>Bio</h3>
      <p className={`body_3`}>
        {bio || "N/A"}
      </p>
    </div>
  );
};

export default Bio;
