import { useState } from "react";
import HamburgerMenu from "react-hamburger-menu";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import st_nav from "./Nav.module.scss";

const MobileNav = ({ toggleNavUI }: any) => {
    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(prev => !prev);
        toggleNavUI();
    }

    const animateFrom = {opacity: 0, y: -40};
    const animateTo = {opacity: 1, y: 0};

    return (
      <>
        <HamburgerMenu
          isOpen={open}
          menuClicked={toggle}
          width={20}
          height={17.5}
          strokeWidth={2}
          rotate={0}
          color="white"
          borderRadius={30}
          animationDuration={0.5}
        />
        {open && (
          <ul className={st_nav.mobile_links}>
            <Link to="/delegations" onClick={toggle}>
              <motion.li
                initial={animateFrom}
                animate={animateTo}
                transition={{ delay: 0.05 }}
                className="title_4"
              >
                Delegations
                <span>
                  <img src="/images/png/ui-assets/chevron_right_white.png" />
                </span>
              </motion.li>
            </Link>
            <Link to="/tokens" onClick={toggle}>
              <motion.li
                initial={animateFrom}
                animate={animateTo}
                transition={{ delay: 0.05 }}
                className="title_4"
              >
                Tokens
                <span>
                  <img src="/images/png/ui-assets/chevron_right_white.png" />
                </span>
              </motion.li>
            </Link>
            <Link to="/nfts" onClick={toggle}>
              <motion.li
                initial={animateFrom}
                animate={animateTo}
                transition={{ delay: 0.05 }}
                className="title_4"
              >
                NFTs
                <span>
                  <img src="/images/png/ui-assets/chevron_right_white.png" />
                </span>
              </motion.li>
            </Link>
            <Link to="/my-profile" onClick={toggle}>
              <motion.li
                initial={animateFrom}
                animate={animateTo}
                transition={{ delay: 0.1 }}
                className="title_4"
              >
                Profile
                <span>
                  <img src="/images/png/ui-assets/chevron_right_white.png" />
                </span>
              </motion.li>
            </Link>
          </ul>
        )}
      </>
    );
}

export default MobileNav;