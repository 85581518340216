import { Text, Grid, GridItem, Box, Image, Link } from "@chakra-ui/react";
import { RADIUS_M, SHADOW_MAIN, WHITE } from "constants/variables";
import {v4 as uuidv4} from 'uuid';

export const templateColumns = {base: 'repeat(6)',sm: 'repeat(6)', md: 'repeat(6, 1fr)', lg: 'repeat(6, 1fr)'};

const validatorGrids = [
  { value: "Terran One", pic:"/T1-3.png", href: "https://terrasco.pe/mainnet/validator/terravaloper1krj7amhhagjnyg2tkkuh6l0550y733jnjnnlzy" },
  { value: "Protoss One", pic:"/P1-3.png", href: "https://terrasco.pe/mainnet/validator/terravaloper1nnm4qx2ytff5gy7a82yh4nw4tnez4s38p4y0g9" },
  { value: "Zerg One", pic:"/Z1-3.png", href: "https://terrasco.pe/mainnet/validator/terravaloper198c72uxt9p8r2u5y6zha4390qjkayvjr3gduxx" },
];

export interface Grid {
  pic?: string;
  value?: string;
  href?: string;
}

export function getGridItems(grids: Grid[]) {
  return grids.map((grid, index) => {
      return (
        <GridItem colSpan={2} key={uuidv4() + index}>
          <Box minH={140} borderWidth="1px" bg={WHITE} borderRadius={RADIUS_M} paddingTop={5} boxShadow={SHADOW_MAIN}>
            {grid.value && <Text className="title_3" mb={19} fontSize="xl" fontWeight="bold" textAlign="center"><Link href={grid.href} isExternal>{grid.value}</Link></Text>}
            {grid.pic && <Image borderRadius="md" w='100%' src={grid.pic} p='17px'/>}
          </Box>
        </GridItem>
      );
  });
}

const Validators = () => {
  return (
    <div>
      <Text className="title_2" mb={2} mt={10} fontSize="xl" fontWeight="bold" textAlign="center" marginTop={81}>Our Validators</Text>
      <Text className="body_1" mb={41} textAlign="center">Stake with us and be part of the Terran One community!</Text>
      <Grid templateColumns={templateColumns} mb={20} mt={41} gap='24px'>
        {getGridItems(validatorGrids)}
      </Grid>
    </div>
  )
};

export default Validators;