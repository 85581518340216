const InfoMolecule = ({ title, info }: { title: string; info: string }) => {
  return (
    <div>
      <h3 className={`body_2`}>{title}</h3>
      <h3 className={`title_4`}>{info || "N/A"}</h3>
    </div>
  );
};

export default InfoMolecule;
