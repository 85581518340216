import { Coin, MsgBeginRedelegate, MsgDelegate, MsgUndelegate, MsgWithdrawDelegatorReward } from "@terra-money/terra.js";
import { ClaimRewardParams, RedelegateParams, StakeUnstakeParams } from "constants/types";

export const delegateMsg = ({ 
    user_address, 
    validatorChosen, 
    howMuch 
}: StakeUnstakeParams) => [new MsgDelegate(
    user_address,
    validatorChosen, 
    new Coin("uluna", howMuch),
)];

export const undelegateMsg = ({ 
    user_address, 
    validatorChosen, 
    howMuch 
}: StakeUnstakeParams) => [new MsgUndelegate(
    user_address,
    validatorChosen, 
    new Coin("uluna", howMuch)
)];

export const redelegateMsg = ({
    user_address,
    validatorInitial,
    validatorDestination,
    howMuch,
}: RedelegateParams) => [new MsgBeginRedelegate(
    user_address, 
    validatorInitial, 
    validatorDestination,
     new Coin("uluna", howMuch)
)];

export const claimRewardsMsg = ({
    user_address, 
    validatorChosen
}: ClaimRewardParams) => [new MsgWithdrawDelegatorReward(
    user_address,
    validatorChosen,
)];