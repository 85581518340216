import { Flex, Text } from "@chakra-ui/react";

const Privacy = () => {
  return (
    <Flex className="privacy" direction='column' pt={{ base: '40px' }} pb={{ base: '40px' }}>
      <Text className="title_1">Privacy Notice</Text>
      <Text className="title_3">Cookies and Automatically Collected Data</Text>

      <Text>As you navigate through and interact with our website and the Services, we may ask your consent to use cookies, 
        which are small files placed on the hard drive of your computer or mobile device, and web beacons, which are small electronic files located on pages of the website, to collect certain information about your equipment, browsing actions, and patterns.
      </Text>

      <Text>The data automatically collected from cookies and web beacons may include information from your web browser (such as browser type and browser language) and details of your visits to our website, including traffic data, location data and logs, 
        page views, length of visit and website navigation paths as well as information about your device and internet connection, including your IP address and how you interact with the Services. We collect this data in order to help us improve our website and the Services.
      </Text>

      <Text>The information we collect automatically may also include statistical and performance information arising from your use of our Services and website. 
        This type of data will only be used by us in an aggregated and anonymized manner.
      </Text>
      
      <Text>
      You can disable/delete the cookies set by our website - please find the appropriate instructions by following these links on how to implement the deletion in different browsers:
      </Text>

      <Text>For Google Chrome browser please refer to this instructions</Text>
      <Text>For Firefox browser please look up here</Text>
      <Text>For Safari browser please visit</Text>
      <Text>For Internet Explorer browser please refer to</Text>

      <Text className="title_3">Personal Data of Children</Text>

      <Text>
      If you are a resident of the US and you are under the age of 13, please do not submit any personal data through the website. If you have reason to believe that a child 
      under the age of 13 has provided personal data to us through the Services, please contact us, and we will endeavour to delete that information from our databases.
      </Text>

      <Text>
      If you are a resident of the European Economic Area and you are under the age of 16, please do not submit any personal data through the Services and the website. We do 
      not collect or process Personal Information pertaining to a child, where a child under the GDPR is defined as an individual below the age of 16 years old.
      </Text>


      <Text className="title_3">
      Your Rights With Regard to the Personal Data Processing
      </Text>

      <Text>
      In connection with the accessing, browsing of the website and using the Services, you shall be entitled to exercise certain rights laid down by the GDPR and outlined herein below, however exercise of some of those rights may not be possible in relation to the website and Services taking account of the Services’ nature, manner, form and other applicable circumstances. In some cases we may ask you to provide us additional evidence and information confirming your identity.
      </Text>
    </Flex>
  )
};

export default Privacy;