import ProfileContainer from "./Modules/ProfileContainer";
import st_pr from "./Profile.module.scss";

const Profile = (): JSX.Element => {
  return (
    <div className={st_pr.profile_container}>
      <h1 className={`caption_1 ${st_pr.title}`}>My Profile</h1>
      <ProfileContainer />
    </div>
  );
};

export default Profile;
