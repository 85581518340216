import { Box, Flex, Heading, Spacer, Text, Button, Stack } from "@chakra-ui/react";
import { CountComponent } from "components/Snippets/Animations/CountComponent";
import { GREY_1, ORANGE_5, RADIUS_M, SHADOW_MAIN, WHITE } from "constants/variables";
import React from "react"

const btnStyle = {
  background: GREY_1,
  color: WHITE,
  borderRadius: RADIUS_M,
  outline: "none",
  boxShadow: "none !important",
  width: "fit-content",
  fontSize: "12px",
  lineHeight: "12px",
  padding: "14px 16px",
};

const MyTokens = () => {
  return (
    <React.Fragment>
      <Flex paddingTop={{ md: "30px", sm: "30px", xs: "30px" }}>
        <Box p='2' className="title_3" color={ORANGE_5}>
          <Heading size='md'>My Tokens</Heading>
        </Box>
        <Spacer />
        <Button style={btnStyle} className="grey_1" mr='14px'>SEND</Button>
        <Button style={btnStyle} className="grey_1">TRADE</Button>
      </Flex>
      <Stack bg={WHITE} minH={220} borderRadius={RADIUS_M} boxShadow={SHADOW_MAIN} justify='center' alignItems='center'>
          <Text className="body_1">Balance</Text>
          <Flex direction='column'>
            <Text className="grey_1" fontWeight='bold'>20.00 TONE</Text>
            <Button maxH='27px' fontSize='12px' className="grey_5">CLAIM</Button>
          </Flex>
          <Text className="body_2">
            Market price <CountComponent separator={','} decimals={2} number={2.35} prefix={'$'}/>
          </Text>
          <Text className="body_2">
            <CountComponent separator={','} decimals={2} number={2656.45} prefix={'-$'}/>
          </Text>
      </Stack>
    </React.Fragment>
  )
};

export default MyTokens;