import Profile from "components/Profile/Profile";
import st_pr from "./ProfilePage.module.scss";

const ProfilePage = () => {
  return (
    <section id={st_pr.profile_container}>
      <Profile />
    </section>
  );
};

export default ProfilePage;
