import { UserProfile } from "constants/types";
import { initializeApp } from "firebase/app";
import { 
  doc, 
  getDoc, 
  getFirestore, 
  setDoc 
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

/* ----- READS ----- */
export const getMyProfile = async (my_address: string) => {
    const docRef = doc(db, "profiles", my_address);
    const docFull = await getDoc(docRef);
    return docFull.data();
}

export const getUserProfile = async (user_address: string) => {
    const docRef = doc(db, "profiles", user_address);
    const docFull = await getDoc(docRef);
    const publicInfo = Object.entries(docFull.data()!).filter(([_, d]) => d.public === true);
    return Object.fromEntries(publicInfo);
}
/* ----- READS ----- */

/* ----- WRITES ----- */
export const saveMyProfile = async (my_address: string, userProfile: UserProfile) => {
  const docRef = doc(db, "profiles", my_address);
  return await setDoc(docRef, userProfile);
}
/* ----- WRITES ----- */