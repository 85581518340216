import { Box, Flex, Grid, Heading, Spacer, Text } from "@chakra-ui/react";
import NFT from "components/NFT/NFT";
import SeeMore from "components/SeeMore/SeeMore";
import { templateColumns } from "components/Validators/Validators";
import { ORANGE_5 } from "constants/variables";
import React, { useState } from "react";

const SHOW_NUM = 3;
const nftGrids = [
  {title: "Galactic Punk #9832", src: "https://d75aawrtvbfp1.cloudfront.net/ipfs%3A%2F%2FQmT9UEurz4n9tE5u5fKhQDvEbxwAZrDDTufQHhC6oCF6YH"},
  {title: "Galactic Punk #6079", src: "https://d75aawrtvbfp1.cloudfront.net/ipfs%3A%2F%2FQmT5uudTen4z5PBxocBycfZq5A1WWS7ZA2rdetXeThYfwd"},
  {title: "Galactic Punk #5011", src: "https://d75aawrtvbfp1.cloudfront.net/ipfs%3A%2F%2FQmZKVe7UMBrjRRQTmTqjHtKxUop1fhS7zUbHkS7K1GMga7"},
  {title: "Galactic Punk #99", src: "https://d75aawrtvbfp1.cloudfront.net/ipfs%3A%2F%2FQmYutRC64rvXqtV2bBqyYs3xjz2fGt9ukJ3gbWvR7jRixV"},
  {title: "Galactic Punk #445", src: "https://d75aawrtvbfp1.cloudfront.net/ipfs%3A%2F%2FQmXibdzceVg3NaJMNXDxhy9aVLAeW4DpWxNAJtJUC8u9y7"},
  {title: "Galactic Punk #5011", src: "https://d75aawrtvbfp1.cloudfront.net/ipfs%3A%2F%2FQmZKVe7UMBrjRRQTmTqjHtKxUop1fhS7zUbHkS7K1GMga7"}
];

const NFTs = () => {
  const [showAll, setShowAll] = useState<boolean>(false);
  return (
    <React.Fragment>
      <Flex paddingTop={{ md: "30px", sm: "30px", xs: "30px" }}>
        <Box p='2' className="title_3" color={ORANGE_5}>
          <Heading size='md'>My NFTs</Heading>
        </Box>
        <Spacer />
        <Box>
          <SeeMore showAll={showAll} onClick={() => setShowAll(!showAll)}></SeeMore>
        </Box>
      </Flex>
      <Grid templateColumns={templateColumns} mb={20} mt='20px' gap='24px'>
        {nftGrids.slice(0, showAll ? nftGrids.length : SHOW_NUM).map((grid, index) => {
          return (
            <NFT src={grid.src} title={grid.title} key={index}></NFT>
          );
        }, [])}
      </Grid>
    </React.Fragment>
  );
};

export default NFTs;