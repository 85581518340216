import { Stack } from "@chakra-ui/react";
import MyTokens from "components/MyTokens/MyTokens";
import Performance from "components/Performance/Performance";
import Transactions from "components/Transactions/Transactions";
import { createContext } from "react";

const Tokens = () => {
  return (
    <Stack mb={20}>
      <MyTokens></MyTokens>
      <Performance></Performance>
      <Transactions></Transactions>
    </Stack>
  );
}

export default Tokens;