import { Image, Stack, Text, Box } from "@chakra-ui/react";
import { useWallet } from "@terra-money/wallet-provider";
import BlackButton from "components/Snippets/Buttons/BlackButton";
import { useT1Validators } from "components/state/global";
import { Delegation } from "constants/types";
import { GREY_4, SHADOW_MAIN, RADIUS_M, ORANGE_5_2, ORANGE_1_2, ORANGE_5 } from "constants/variables";
import { fetcher } from "functions/fetcher";
import { getAbbreviation } from "functions/getAbbreviation";
import { uiAmount } from "functions/getUIAmount";
import { getValidatorInfo } from "functions/getValidatorInfo";
import { queryFinder } from "functions/queryFinder";
import useSWR from "swr";

const notT1Validator = (delegation: any, t1Validators: Delegation[]) => 
t1Validators.every(val => delegation.validatorName !== val.validatorName);

const DelegationComponent = ({ delegation, setManage }: any) => {
  const { network } = useWallet();
  const [t1Validators] = useT1Validators("t1Validators");

  const { data: validatorInfo } = useSWR(
    delegation.validatorAddress
      ? getValidatorInfo(delegation.validatorAddress, network.chainID)
      : null,
    fetcher
  );

  return (
    <Stack
      background="white"
      border={`1px solid ${GREY_4}`}
      borderRadius="21px"
      boxShadow={SHADOW_MAIN}
      direction={{
        xl: "row",
        lg: "row",
        md: "row",
        sm: "column",
        xs: "column",
      }}
      justifyContent="space-between"
      alignItems={{ md: "center", sm: "left" }}
      width="100%"
      height={{ xl: "200px", lg: "200px", md: "200px", sm: "auto", xs: "auto" }}
      padding={{ md: "35px 32px", sm: "35px 25px", xs: "35px 25px" }}
      gap={{ md: "0", sm: "25px", xs: "25px" }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        minWidth="340px"
        height="100%"
        gap={{ md: "24px", sm: "20px", xs: "15px" }}
        paddingRight={{ md: "50px", sm: "0", xs: "15px" }}
      >
        {validatorInfo?.description?.profileIcon ? (
          <Image
            src={validatorInfo.description.profileIcon}
            borderRadius={RADIUS_M}
            width={{ md: "60px", sm: "45px", xs: "45px" }}
            height={{ md: "60px", sm: "45px", xs: "45px" }}
          />
        ) : (
          <Box
            cursor="default"
            display="grid"
            placeItems="center"
            background={ORANGE_1_2}
            border={`1px solid ${ORANGE_5_2}`}
            borderRadius={RADIUS_M}
            width={{ md: "60px", sm: "45px", xs: "45px" }}
            height={{ md: "60px", sm: "45px", xs: "45px" }}
          >
            <Text className="title_3" color={ORANGE_5}>
              {getAbbreviation(delegation.validatorName)}
            </Text>
          </Box>
        )}
        <Stack height="100%" direction="column" justifyContent="space-between">
          <Stack direction="column" gap="3px">
            <Text className="caption_1">{delegation.validatorName}</Text>
            <a
              target="_blank"
              href={queryFinder(network.name, delegation.validatorAddress)}
              className="body_3"
              style={{
                width: "fit-content",
                paddingBottom: "2px",
                borderBottom: `1px solid ${GREY_4}`,
              }}
            >
              See validator on Terrascope
            </a>
          </Stack>
          <Box padding="0">
            <a className="body_2">Total LUNAs Delegated</a>
            <Text className="title_4">
              {uiAmount(parseInt(validatorInfo?.votingPower?.amount ?? 0))}{" "}
              <span className="body_3">
                ({((+validatorInfo?.votingPower?.weight || 0) * 100).toFixed(2)}
                % voting power)
              </span>
            </Text>
          </Box>
        </Stack>
      </Stack>
      <Stack
        direction={{
          xl: "row",
          lg: "row",
          md: "column",
          sm: "column",
          xs: "column",
        }}
        height="100%"
        width="100%"
        justifyContent="space-between"
        alignItems={{
          xl: "center",
          lg: "center",
          md: "left",
          sm: "left",
          xs: "left",
        }}
        borderLeft={{ md: `1px solid ${GREY_4}`, sm: "none" }}
        paddingLeft={{ md: "25px", sm: "68px", xs: "68px" }}
      >
        <Stack
          height="100%"
          paddingTop={{ md: "10px", sm: "25px", xs: "25px" }}
          borderTop={{
            md: "none",
            sm: `1px solid ${GREY_4}`,
            xs: `1px solid ${GREY_4}`,
          }}
          marginBottom={{ md: "0", sm: "25px", xs: "20px" }}
        >
          <Text className="body_1">Coins Delegated</Text>
          <Text className="body_1">
            <span className="title_2">
              {uiAmount(delegation.amountDelegated)}
            </span>{" "}
            LUNA
          </Text>
        </Stack>
        <BlackButton
          text={
            delegation?.validatorStatus === "not_staked"
              ? "Stake"
              : notT1Validator(delegation, t1Validators)
              ? "Redelegate"
              : "Manage"
          }
          onClick={setManage}
        />
      </Stack>
    </Stack>
  );
};

export default DelegationComponent;
