import {
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  UserDenied,
  CreateTxFailed,
  TxFailed,
  TxUnspecifiedError,
  Timeout,
  TxResult,
  useLCDClient,
  useConnectedWallet,
  useWallet,
} from "@terra-money/wallet-provider";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { mutate } from "swr";

import st_tx from "./TxModal.module.scss";
import { sliceAddress } from "functions/sliceAddress";
import { DelegationMsgs } from "constants/types";
import { queryFinder } from "functions/queryFinder";
import { stakingInfo } from "functions/getRoutes";
import { getSetBalances } from "components/Wrappers/StateWrapper";

export enum TxStep {
  /*Before Wallet Signing*/
  Idle = 0,
  /*Before Wallet Signing*/

  /*Waiting for Wallet Signature*/
  Waiting = 1,
  /*Waiting for Wallet Signature*/

  /*Tx Hash created: Waiting for Info Query*/
  Hashed = 2,
  /*Tx Hash created: Waiting for Info Query*/

  /*Tx Broadcasted Successfully*/
  Success = 3,
  /*Tx Broadcasted Successfully*/

  /*Tx Failed*/
  Failed = 4,
  /*Tx Failed*/
}

const TxModal = ({ msgs }: { msgs: DelegationMsgs }): JSX.Element => {
  const client = useLCDClient();
  const wallet = useConnectedWallet();
  const { network } = useWallet();

  const [open, setOpen] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>("");
  const [status, setStatus] = useState<TxStep>(TxStep.Idle);
  const [error, setError] = useState<string>("");

  const reset = () => {
    mutate(stakingInfo(wallet?.walletAddress!, network.chainID));
    getSetBalances(client, wallet?.walletAddress!);
    setStatus(TxStep.Idle);
    setTxHash("");
    setError("");
    setOpen(false);
  };

  const Dialog = ({ type, title, hash }: any) => {
    return (
      <div className={st_tx.dialog}>
        <Text className={`title_2 ${st_tx.title}`}>{title}</Text>
        <div className={st_tx.graphics}>
          {type === TxStep.Waiting && (
            <img
              className={st_tx.rotate}
              src="/images/svg/ui-assets/waiting.svg"
              alt="success"
            />
          )}
          {type === TxStep.Hashed && (
            <img
              className={st_tx.rotate}
              src="/images/svg/ui-assets/waiting.svg"
              alt="success"
            />
          )}
          {type === TxStep.Success && (
            <img src="/images/svg/ui-assets/success.svg" alt="success" />
          )}
        </div>
        {type !== TxStep.Waiting && (
          <>
            <Text className={`title_5 ${st_tx.hash_title}`}>
              Transaction Hash:
            </Text>
            <Text className={`body_3 ${st_tx.hash}`}>
              {sliceAddress(hash, 15, -15)}
            </Text>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <a
                href={queryFinder(network.name, txHash)}
                target="_blank"
                className={`body_3 ${st_tx.finder}`}
              >
                See tx details on Terrascope 🡥
              </a>
              {type === TxStep.Success || type === TxStep.Failed ? (
                <Text onClick={reset} className={`title_4 ${st_tx.go_back}`}>
                  Go Back
                </Text>
              ) : (
                <div />
              )}
            </Stack>
          </>
        )}
      </div>
    );
  };

  /* Query to get txInfo after Hash is created */
  const { data: txInfo } = useQuery(
    ["txInfo", txHash],
    () => {
      if (txHash === "") return;

      return client.tx.txInfo(txHash);
    },
    {
      enabled: status !== TxStep.Idle,
      retry: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
  /* Query to get txInfo after Hash is created */

  useEffect(() => {
    if (msgs.length === 0 || !wallet) return;

    (async () => {
      setError("");

      let res: TxResult | string;

      try {
        if (!client) {
          throw new Error("Error in LCDClient");
        }

        setStatus(TxStep.Waiting);

        /* Wallet Transaction */
        res = await wallet.post({
          msgs,
        });

        setTxHash(res.result.txhash);
        setStatus(TxStep.Hashed);
        /* Wallet Transaction */
      } catch (error: unknown) {
        if (error instanceof UserDenied) {
          res = "User Denied";
          console.log(res);
        } else if (error instanceof CreateTxFailed) {
          res = "Create Tx Failed: " + error.message;
          console.log(res);
        } else if (error instanceof TxFailed) {
          res = "Tx Failed: " + error.message;
          console.log(res);
        } else if (error instanceof Timeout) {
          res = "Timeout";
        } else if (error instanceof TxUnspecifiedError) {
          res = "Unspecified Error: " + error.message;
          console.log(res);
        } else {
          res = error instanceof Error ? error.message : String(error);
          console.log(res);
        }
        setStatus(res === "User Denied" ? TxStep.Idle : TxStep.Failed);
        setError(res);
      }
    })();
  }, [msgs]);

  useEffect(() => {
    if (txInfo != null && txHash !== "") {
      if (txInfo.code) {
        setStatus(TxStep.Failed);
      } else {
        setStatus(TxStep.Success);
      }
    }
  }, [txInfo, txHash]);

  useEffect(() => {
    if (status !== TxStep.Idle) {
      setOpen(true);
    }
  }, [status]);

  useEffect(() => {
    if (error === "User Denied") {
      setOpen(false);
    }
  }, [error]);

  return (
    <Modal isCentered isOpen={open} onClose={() => null} size="md">
      <ModalOverlay />
      <ModalContent margin="15px">
        {status === TxStep.Waiting && (
          <Dialog
            img="images/svg/ui-assets/terran_one_orange_cube_transparent_background.svg"
            title="Waiting on Wallet"
            type={TxStep.Waiting}
          />
        )}
        {status === TxStep.Hashed && (
          <Dialog
            img="images/svg/ui-assets/terran_one_orange_cube_transparent_background.svg"
            title="Broadcasting"
            hash={txHash}
            type={TxStep.Hashed}
          />
        )}
        {status === TxStep.Success && (
          <Dialog
            img="images/svg/ui-assets/terran_one_orange_cube_transparent_background.svg"
            title="Tx Success"
            hash={txHash}
            type={TxStep.Success}
          />
        )}
        {status === TxStep.Failed && (
          <Dialog
            img="images/svg/ui-assets/terran_one_orange_cube_transparent_background.svg"
            title="Tx Failed"
            hash={txHash}
            type={TxStep.Failed}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default TxModal;
