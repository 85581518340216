import { Button } from "@chakra-ui/react";
import { ORANGE_1_2, RADIUS_XL } from "constants/variables";

interface SeeMoreProps {
  showAll?: boolean;
  onClick?: () => void;
};

const SeeMore: React.FC<SeeMoreProps> = ({ showAll, onClick }) => {
  return (
    <Button mr='0' w='109px' h='34px' bg={ORANGE_1_2} variant='solid' onClick={onClick} borderRadius={RADIUS_XL}>
      {!showAll ? 'See more >' : 'See less >'}
    </Button>
  );
};

export default SeeMore;