import { Box, grid, GridItem, Link, Image, Text } from "@chakra-ui/react";
import { RADIUS_M, SHADOW_MAIN, WHITE } from "constants/variables";
import React from "react";
import {v4 as uuidv4} from 'uuid';

export type NFTProps = {
  src: string;
  title?: string;
  index?: number;
};

const NFT: React.FC<NFTProps> = ({src, title, ...props}) => {
  return (
    <GridItem colSpan={2} key={props.index}>
      <Box minH={140} borderWidth="1px" bg={WHITE} borderRadius={RADIUS_M} paddingTop={5} boxShadow={SHADOW_MAIN}>
        {src && <Image borderRadius="md" w='100%' src={src} p='17px'/>}
        {title && <Text className="title_3" mb={19} fontSize="xl" fontWeight="bold" textAlign="center">{title}</Text>}
      </Box>
    </GridItem>
  );
};

export default NFT;