import { Center, Text } from "@chakra-ui/react";
import { useWallet, WalletStatus } from "@terra-money/wallet-provider";
import Connector from "components/Nav/WalletProvider/Connector";
import ConnectButton from "components/Snippets/Buttons/ConnectButton";
import Stats from "components/Stats/Stats";
import Validators from "components/Validators/Validators";
import { GREY_2 } from "constants/variables";
import { useHistory } from "react-router-dom";

const Home = () => {
  const { status } = useWallet();
  const history = useHistory();

  return (
    <section>
      <Center mt={70}>
        <Text className="cta_1" color={GREY_2}>
          TERRAN ONE
        </Text>
      </Center>
      <Center mt={33}>
        <Text
          className="hero_1_2"
          mb={{ base: 10, md: 65 }}
          lineHeight={{ base: "40px", sm: "40px", md: "56px", lg: "80px" }}
          textAlign="center"
          fontSize={{ base: 34, sm: 34, md: 50, lg: 74 }}
          maxW={{ base: 299, sm: 299, md: 534, lg: 833 }}
        >
          Choose to delegate with Terran One
        </Text>
      </Center>
      <Center>
        {status !== WalletStatus.WALLET_CONNECTED ? (
          <Connector
            text="Connect Wallet"
            style={{
              borderRadius: "4px",
            }}
          />
        ) : (
          <ConnectButton
            style={{
              borderRadius: "4px",
            }}
            onClick={() => history.push("/delegations")}
            text="Delegate Now"
          />
        )}
      </Center>
      <Center mt={38}>
        <Text
          mt={2}
          textAlign="center"
          className="body_1"
          maxW={{ base: 289, sm: 289, md: 490, lg: 526 }}
        >
          Every delegation to Terran One supports us in publishing world-class
          developer tooling for Terra and enables us to offer it open-source for
          free.
        </Text>
      </Center>
      <Stats />
      <Validators />
    </section>
  );
};

export default Home;
