import { Stack, Text, Image, SimpleGrid } from "@chakra-ui/react"
import { GREY_4, RADIUS_M, SHADOW_MAIN } from "constants/variables";
import { fetcher } from "functions/fetcher";
import { formatDate } from "functions/formatDate";
import { uiAmount } from "functions/getUIAmount";
import { getValidatorInfo } from "functions/getValidatorInfo";
import { queryFinder } from "functions/queryFinder";
import useSWR from "swr";

const Undelegation = ({ network, undelegation }: any) => {
  const { data: validatorInfo } = useSWR(getValidatorInfo(undelegation.validatorAddress, network.chainID), fetcher);

    return (
      <SimpleGrid
        background="white"
        border={`1px solid ${GREY_4}`}
        borderRadius="21px"
        boxShadow={SHADOW_MAIN}
        width="100%"
        height={{ lg: "130px", md: "auto" }}
        padding="30px 25px"
        rowGap={{ lg: "0", md: "30px", sm: "30px", xs: "30px" }}
        gridTemplateColumns={{
          lg: "23fr 15fr 10fr 18fr",
          md: "repeat(2, 1fr)",
          sm: "1fr",
          xs: "1fr",
        }}
      >
        <Stack
          direction="row"
          gap="23px"
          minWidth="250px"
          height={{ md: "100%", sm: "fit-content", xs: "fit-content" }}
        >
          <Image
            src={validatorInfo?.description?.profileIcon ?? ""}
            borderRadius={RADIUS_M}
            width={{ md: "75px", sm: "48px", xs: "48px" }}
            height={{ md: "75px", sm: "48px", xs: "48px" }}
          />
          <Stack direction="column">
            <Text className="title_3">{undelegation.validatorName}</Text>
            <a
              target="_blank"
              href={queryFinder(network.name, undelegation.validatorAddress)}
              className="body_3"
              style={{
                paddingBottom: "2px",
                borderBottom: `1px solid ${GREY_4}`,
              }}
            >
              See validator on Terrascope
            </a>
          </Stack>
        </Stack>
        <Stack
          marginLeft={{ lg: "0", md: "0", sm: "78px", xs: "78px" }}
          padding={{ lg: "3px 0 3px 23px", md: "5px 0" }}
          borderLeft={{ lg: `1px solid ${GREY_4}`, md: "none" }}
          height={{ md: "100%", sm: "fit-content", xs: "fit-content" }}
          direction="column"
          justifyContent="space-between"
        >
          <Text className="body_2">Coins Undelegated</Text>
          <Text className="title_2">
            {uiAmount(undelegation.amount)} <span className="body_1">LUNA</span>
          </Text>
        </Stack>
        <Stack
          marginLeft={{ lg: "0", md: "0", sm: "78px", xs: "78px" }}
          padding={{ lg: "3px 0 3px 23px", md: "25px 0 15px 0" }}
          borderTop={{ lg: "none", md: `1px solid ${GREY_4}` }}
          borderLeft={{ lg: `1px solid ${GREY_4}`, md: "none" }}
          height={{ md: "100%", sm: "fit-content", xs: "fit-content" }}
          direction="column"
          justifyContent="space-between"
        >
          <Text className="body_2">Block</Text>
          <Text className="body_1">{undelegation.creationHeight}</Text>
        </Stack>
        <Stack
          marginLeft={{ lg: "0", md: "0", sm: "78px", xs: "78px" }}
          padding={{ lg: "3px 0 3px 23px", md: "25px 0 15px 0" }}
          borderTop={{ lg: "none", md: `1px solid ${GREY_4}` }}
          borderLeft={{ lg: `1px solid ${GREY_4}`, md: "none" }}
          height={{ md: "100%", sm: "fit-content", xs: "fit-content" }}
          direction="column"
          justifyContent="space-between"
        >
          <Text className="body_2">Release Time</Text>
          <Text className="body_1">{formatDate(undelegation.releaseTime)}</Text>
        </Stack>
      </SimpleGrid>
    );
}

export default Undelegation;
