import { Stack, Switch, Textarea, Text, Input } from "@chakra-ui/react";

export const TextBoxComponent = ({
  title,
  placeHolder,
  value,
  toggle,
  onChange,
  checked,
}: any): JSX.Element => (
  <Stack direction="column" mt="30">
    <Stack direction="row" justifyContent="space-between">
      <Text className="title_4" textTransform="uppercase">
        {title}
      </Text>
      {/* <Switch 
        defaultChecked={checked}
        onChange={toggle}/> */}
    </Stack>
    <Textarea
      value={value}
      placeholder={placeHolder}
      onChange={onChange}
      border="2px solid #D1D1D1"
    />
  </Stack>
);

export const InputComponent = ({
  isInvalid,
  title,
  placeHolder,
  value,
  toggle,
  onChange,
  checked,
}: any): JSX.Element => (
  <Stack direction="column" mt="30">
    <Stack direction="row" justifyContent="space-between">
      <Text className="title_4" textTransform="uppercase">
        {title}
      </Text>
      {/* <Switch 
        defaultChecked={checked}
        onChange={toggle}/> */}
    </Stack>
    <Input
      isInvalid={isInvalid ?? false}
      value={value}
      placeholder={placeHolder}
      onChange={onChange}
      border="2px solid #D1D1D1"
      height="50px"
      type="text"
    />
    {isInvalid && <Text className="cta_2" color="red.500">Please input a valid email</Text>}
  </Stack>
);