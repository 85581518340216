import {
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    Image,
    Box,
    Radio,
    RadioGroup
} from "@chakra-ui/react";
import BlackButton from "components/Snippets/Buttons/BlackButton";
import { useEffect, useMemo, useState } from "react";
import { toChainAmount, toTerraAmount } from "functions/toXAmount";
import { useBalance, useStakeInfo, useT1Validators } from "components/state/global";
import useAddress from "hooks/useAddress";
import { uiAmount } from "functions/getUIAmount";
import { Wanna } from "constants/enums";
import { delegateMsg, redelegateMsg, undelegateMsg } from "functions/msgs";
import { GREY_1, RADIUS_S } from "constants/variables";
import st_modal from "../../../Modal/Modal.module.scss";
import { getSetBalances } from "components/Wrappers/StateWrapper";
import { useLCDClient } from "@terra-money/wallet-provider";

export default function DelegationModal({ delState, setDelState }: any) {
  /* ----- HOOKS AND STATES ----- */
  const user_address = useAddress();
  const LCD = useLCDClient();
  const [t1Validators] = useT1Validators("t1Validators");
  const [{ delegations }] = useStakeInfo("delegatedInfo");
  const [luna_balance] = useBalance("uluna");
  const [redelegationValidator, setRedelegationValidator] = useState("0");

  const balance = useMemo(() => {
    const [delegated] = delegations.filter(
      (d) => d.validatorAddress === delState.validatorChosen
    );

    return !t1Validators.some(
      (val) => val.validatorAddress === delState.validatorChosen
    ) || delState.wanna === Wanna.Unstake
      ? +delegated?.amountDelegated ?? 0
      : luna_balance;
  }, [delState.wanna, luna_balance, delegations, delState.validatorChosen]);

  useEffect(() => {
    if (
      t1Validators.some(
        (val) => val.validatorAddress === delState.validatorChosen
      )
    ) {
      const status = delegations.filter(
        (d) => d.validatorAddress === delState.validatorChosen
      )[0]?.validatorStatus;
      setDelState({ status });
    }
  }, [delState.validatorChosen]);
  /* ----- HOOKS AND STATES ----- */

  /* ----- FUNCTIONS / LOGIC ----- */
  const setWannaStake = () => {
    setDelState({
      wanna: Wanna.Stake,
      howMuch: undefined,
    });
  };

  const setWannaUnstake = () => {
    setDelState({
      wanna: Wanna.Unstake,
      howMuch: undefined,
    });
  };

  const setInputAmount = (e: any) => {
    const number = +e.target.value;
    const meets_condition =
      !(Math.round(number * 1e6) / 1e6 != number) &&
      balance >= toChainAmount(number);
    if (meets_condition) {
      setDelState({ howMuch: toChainAmount(number) });
    }
  };

  const setMaxBalance = () => {
    setDelState({ howMuch: balance });
  };

  const resetModal = () => {
    setDelState({
      open: false,
      howMuch: undefined,
      wanna: Wanna.Stake,
      validatorChosen: "",
    });
    setRedelegationValidator("0");
    getSetBalances(LCD, user_address);
  };

  const setMsg = () => {
    if (!delState.howMuch) return;
    const msgs = !t1Validators.some(
      (val) => val.validatorAddress === delState.validatorChosen
    )
      ? redelegateMsg({
          user_address,
          validatorInitial: delState.validatorChosen,
          validatorDestination:
            t1Validators[parseInt(redelegationValidator)].validatorAddress,
          howMuch: delState.howMuch,
        })
      : delState.wanna === Wanna.Stake
      ? delegateMsg({
          user_address,
          validatorChosen: delState.validatorChosen,
          howMuch: delState.howMuch,
        })
      : undelegateMsg({
          user_address,
          validatorChosen: delState.validatorChosen,
          howMuch: delState.howMuch,
        });

    resetModal();
    setDelState({ msgs });
  };
  /* ----- FUNCTIONS / LOGIC ----- */

  return (
    <Modal
      autoFocus={false}
      isCentered
      isOpen={delState.open}
      onClose={() => null}
      size="md"
    >
      <ModalOverlay />
      <ModalContent className={st_modal.modal} margin="15px">
        <Stack className={st_modal.inner_container}>
          <Text className={`title_2 ${st_modal.title}`}>
            {t1Validators.some(
              (val) => val.validatorAddress === delState.validatorChosen
            )
              ? delState.status === "active"
                ? "Manage"
                : "Stake"
              : "Redelegate"}
            <span>
              <Image
                className={st_modal.close_button}
                src="/images/svg/ui-assets/modal_close.svg"
                onClick={resetModal}
              />
            </span>
          </Text>
          {t1Validators.length !== 0 &&
            !t1Validators.some(
              (val) => val.validatorAddress === delState.validatorChosen
            ) && (
              <>
                <Text className={`body_2 ${st_modal.redelegate_msg}`}>
                  Redelegate your coins with us and be part of the Terran One
                  community!
                </Text>
                <Box className={st_modal.redelegation_choices}>
                  <Text className="title_5" mb="7px">
                    TO
                  </Text>
                  <RadioGroup
                    onChange={setRedelegationValidator}
                    value={redelegationValidator}
                  >
                    <Stack direction="column">
                      {t1Validators.map((val, i) => {
                        return (
                          <Stack
                            onClick={() => setRedelegationValidator(`${i}`)}
                            cursor="pointer"
                            direction="row"
                            gap="15px"
                            padding="19px"
                            maxWidth="338px"
                            border={`2px solid ${GREY_1}`}
                            borderRadius={RADIUS_S}
                            userSelect="none"
                            key={i}
                          >
                            <Radio
                              size="lg"
                              colorScheme="green"
                              border="2px solid black"
                              value={`${i}`}
                            />
                            <Text className="title_4">{val.validatorName}</Text>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                </Box>
              </>
            )}
          {t1Validators.some(
            (val) => val.validatorAddress === delState.validatorChosen
          ) &&
            delState.status === "active" && (
              <div className={st_modal.stake_unstake_nav}>
                <button
                  className={st_modal.choice}
                  style={{
                    outline: "none",
                    boxShadow: "none",
                    fontWeight: "600",
                    border:
                      delState.wanna === Wanna.Stake
                        ? "2px solid #383838"
                        : "2px solid white",
                    background:
                      delState.wanna === Wanna.Stake ? "#DEE3EB" : "none",
                    color: "#383838",
                  }}
                  onClick={setWannaStake}
                >
                  Stake
                </button>
                <button
                  className={st_modal.choice}
                  style={{
                    outline: "none",
                    boxShadow: "none",
                    fontWeight: "600",
                    border:
                      delState.wanna === Wanna.Unstake
                        ? "2px solid #383838"
                        : "2px solid white",
                    background:
                      delState.wanna === Wanna.Unstake ? "#DEE3EB" : "none",
                    color: "#383838",
                  }}
                  onClick={setWannaUnstake}
                >
                  Unstake
                </button>
              </div>
            )}
          {delState.wanna === Wanna.Stake && (
            <div className={st_modal.stake_info}>
              <h5 className="title_5">AMOUNT</h5>
              <div className={st_modal.number}>
                <input
                  className="title_2"
                  value={
                    delState.howMuch
                      ? toTerraAmount(delState.howMuch)
                      : undefined
                  }
                  onChange={setInputAmount}
                  type="number"
                  placeholder="0.00"
                />
                <button onClick={setMaxBalance} className={st_modal.max_btn}>
                  MAX
                </button>
              </div>
              <h4 className={`body_3 ${st_modal.total_balance}`}>
                {!t1Validators.some(
                  (val) => val.validatorAddress === delState.validatorChosen
                ) ? (
                  <>{uiAmount(balance)} LUNA available to redelegate</>
                ) : (
                  <>{uiAmount(balance)} LUNA available for staking</>
                )}
              </h4>
            </div>
          )}
          {delState.wanna === Wanna.Unstake && (
            <div className={st_modal.stake_info}>
              <h5 className="title_5">AMOUNT</h5>
              <div className={st_modal.number}>
                <input
                  className="title_2"
                  value={
                    delState.howMuch
                      ? toTerraAmount(delState.howMuch)
                      : undefined
                  }
                  onChange={setInputAmount}
                  type="number"
                  placeholder="0.00"
                />
                <button onClick={setMaxBalance} className={st_modal.max_btn}>
                  MAX
                </button>
              </div>
              <h4 className={`body_3 ${st_modal.total_balance}`}>
                {uiAmount(balance)} LUNA currently staked
              </h4>
            </div>
          )}
          <BlackButton text="Confirm" onClick={setMsg} />
        </Stack>
      </ModalContent>
    </Modal>
  );
}