import { useWallet } from "@terra-money/wallet-provider";
import ConnectedButton from "components/Snippets/Buttons/ConnectedButton";
import useAddress from "hooks/useAddress";
import {
    Menu,
    MenuList,
    MenuButton,
    Stack,
  } from '@chakra-ui/react'
import { sliceAddress } from "functions/sliceAddress";
import st_connector from "./Connector.module.scss";

const ConnectorSatisfied = () => {
    const user_address = useAddress();
    const { disconnect } = useWallet();

    return (
      <Menu matchWidth>
        <MenuButton>
          <ConnectedButton text={`${sliceAddress(user_address, 5, -5)}`} />
        </MenuButton>
        <MenuList className={st_connector.menu}>
          <Stack className={st_connector.stack}>
            <h1
              onClick={disconnect}
              className={`title_5 ${st_connector.disconnect}`}
            >
              Disconnect
            </h1>
          </Stack>
        </MenuList>
      </Menu>
    );
}

export default ConnectorSatisfied;