import { Stack } from "@chakra-ui/react";
import MyDelegations from "components/Dashboard/Delegations/MyDelegations";
import Overview from "components/Dashboard/Delegations/Overview";
import MyUndelegations from "components/Dashboard/Undelegations/MyUndelegations";

const Dashboard = () => {
  return (
    <Stack
      overflowX="hidden"
      direction="column"
      gap="60px"
      margin={{ md: "60px 0", sm: "35px 0", xs: "35px 0" }}
    >
      <Overview />
      <MyDelegations />
      <MyUndelegations />
    </Stack>
  );
};

export default Dashboard;
