import {
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Stack,
  Image,
  Divider,
  Checkbox,
} from "@chakra-ui/react";
import { useWallet, ConnectType } from "@terra-money/wallet-provider";
import { useState } from "react";
import ConnectButton from "components/Snippets/Buttons/ConnectButton";
import { useLocalStorage } from "react-use";
import st_connect from "./Connector.module.scss";
import {v4 as uuidv4} from 'uuid';
import React from "react";

const Connector = ({ text, style }: { text: string; style: any }) => {
  const { availableConnections, connect } = useWallet();
  const [toc, setToc] = useLocalStorage("toc", "false");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [accept, setAccept] = useState(false);

  const changeAccept = () => setAccept((prev) => !prev);

  const acceptTerms = () => {
    if (!accept) return;

    setToc("true");
  };

  const connectAndRedirect = (type: any, identifier: any) => {
    connect(type, identifier);
  };

  return (
    <>
      <ConnectButton
        style={!style ? { display: "inline-block" } : style}
        onClick={() => setOpenModal(!openModal)}
        text={text}
      />

      <Modal isCentered isOpen={openModal} onClose={() => null} size="md">
        <ModalOverlay />
        <ModalContent className={st_connect.modal} margin="15px">
          <Stack className={st_connect.inner_container}>
            {toc === "false" ? (
              <>
                <Text className={`title_2 ${st_connect.title}`} key={uuidv4()}>
                  Accept Terms
                  <img
                    className={st_connect.close_button}
                    src="/images/svg/ui-assets/modal_close.svg"
                    onClick={() => setOpenModal(false)}
                    key={uuidv4()}
                  />
                </Text>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  gap="20px"
                  className={st_connect.accept_terms}
                  key={uuidv4()}
                >
                  <Checkbox
                    marginTop="3px"
                    size="lg"
                    boxShadow="none"
                    outline="none"
                    colorScheme="green"
                    isRequired
                    isChecked={accept}
                    onChange={changeAccept}
                  />
                  <p className={`body_2 ${st_connect.conditions}`}>
                    I have read and accept{" "}
                    <span>
                      <a>Terms of Service</a>
                    </span>{" "}
                    and{" "}
                    <span>
                      <a>Privacy Notice</a>
                    </span>
                    .{" "}
                  </p>
                </Stack>
                <div className={st_connect.accept_button}>
                  <button
                    style={
                      !accept
                        ? { display: "inline-block" }
                        : {
                            background: "#2d2d2d",
                            cursor: "pointer",
                          }
                    }
                    className="cta_1"
                    onClick={acceptTerms}
                  >
                    Continue
                  </button>
                </div>
              </>
            ) : (
              <>
                <Text className={`title_2 ${st_connect.title}`}>
                  Connect Wallet
                  <img
                    className={st_connect.close_button}
                    src="/images/svg/ui-assets/modal_close.svg"
                    onClick={() => setOpenModal(false)}
                  />
                </Text>
                <Stack className={st_connect.wallets}>
                  {availableConnections.map((conn, i) => {
                    return (
                      <React.Fragment key={i}>
                        {conn.type !== ConnectType.READONLY && (
                          <Stack
                            onClick={() =>
                              connectAndRedirect(conn.type, conn.identifier)
                            }
                            direction="row"
                            alignItems="center"
                            gap="20px"
                            cursor="pointer"
                          >
                            <Image
                              width="35px"
                              height="35px"
                              padding="5px"
                              backgroundColor="transparent"
                              border="2px solid #FD8204"
                              borderRadius="50%"
                              src={conn.icon}
                            />
                            <Text className="title_3">
                              {conn.name}
                            </Text>
                          </Stack>
                        )}
                        {i < availableConnections.length - 2 && (
                          <Divider key={i} margin="0" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </Stack>
              </>
            )}
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Connector;
