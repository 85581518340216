import { useState } from "react";
import { Image } from "@chakra-ui/react";
import ManipulateProfileModal from "components/Profile/Modules/ManipulateProfileModal/ManipulateProfileModal";
import Linker from "components/Snippets/Buttons/Linker";
import { sliceAddress } from "functions/sliceAddress";
import useAddress from "hooks/useAddress";
import InfoMolecules from "./InfoMolecules/InfoMolecules";
import Bio from "./Bio";
import { useMyProfile } from "components/state/global";
import BlackButtonRound from "components/Snippets/Buttons/BlackButtonRound";

import st_pr from "../Profile.module.scss";
import { Stage } from "constants/enums";
import { queryFinder } from "functions/queryFinder";
import { useWallet } from "@terra-money/wallet-provider";
import { getRandomName } from "functions/getRandomName";

const ProfileContainer = () => {
  const user_address = useAddress();
  const { network } = useWallet();
  const [profile] = useMyProfile("profile");
  const [profileState] = useMyProfile("profileState");

  const [open, setOpen] = useState<boolean>(false);

  const setOpenModal = () => {
    setOpen(true);
  };

  const setCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <ManipulateProfileModal open={open} close={setCloseModal} />
      <div className={st_pr.profile}>
        <img
          src="/images/svg/ui-assets/user_icon.svg"
          className={st_pr.user_icon}
        />
        <div>
          <div className={st_pr.user_name_addr}>
            <h1 className={`title_2 ${st_pr.name}`}>
              {profile.user_name.value ? profile.user_name.value : "..."}
              {profileState === Stage.HAS_PROFILE && (
                <Linker to="#" text="Edit Profile" onClick={setOpenModal} />
              )}
            </h1>
            <a
              target="_blank"
              href={queryFinder(network.name, user_address)}
              className={`special_1 ${st_pr.user_address}`}
            >
              {sliceAddress(user_address, 10, -10)} ↗
            </a>
          </div>
        </div>
        {profileState === Stage.HAS_PROFILE && (
          <>
            <Bio bio={profile.bio.value} />
            <InfoMolecules profile={profile} />
          </>
        )}
        {profileState === Stage.NO_PROFILE && (
          <div className={st_pr.create_profile}>
            <BlackButtonRound text="Create Profile" onClick={setOpenModal} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileContainer;
