import { useLCDClient, useWallet } from "@terra-money/wallet-provider";
import {
  setBalance,
  setRoute,
  setStakeInfo,
  setMyProfile,
  initialStakeState,
  setT1Validators,
  useT1Validators,
  setValidatorStats,
  setExchageRate,
} from "components/state/global";
import { fetcher } from "functions/fetcher";
import { stakingInfo } from "functions/getRoutes";
import useAddress from "hooks/useAddress";
import { initialProfile } from "pages/Profile/ProfileModules";
import { useEffect } from "react";
import { getMyProfile } from "services/firestore";
import useSWR from "swr";
import { useHistory, useLocation } from "react-router-dom";
import { Stage } from "constants/enums";
import { t1Validators } from "constants/t1Validators";
import { Delegation } from "constants/types";
import { lunaUSDExRateEndpoint, validatorStatsEndpoint } from "constants/endpoints";
import { LCDClient } from "@terra-money/terra.js";
import { getRandomName } from "functions/getRandomName";

const isT1Validator = (name: string, delegation: any) => {
  return (
    delegation.validatorName === name &&
    delegation?.validatorStatus !== "not_staked"
  );
};

export const getSetBalances = async (LCD: LCDClient, user_address: string) => {
  const [coins] = await LCD.bank.balance(user_address);

  const [uluna_balance] = coins
    .toData() //the api returns alphabetically
    .filter((coin) => coin.denom === "uluna");

  setBalance("uluna", uluna_balance ? +uluna_balance.amount : 0);
};

const StateWrapper = ({ children }: any) => {
  const user_address = useAddress();
  const { network } = useWallet();
  const history = useHistory();
  const LCD = useLCDClient();
  let location = useLocation();
  const [t1ValidatorState] = useT1Validators("t1Validators");

  const { data: delegations } = useSWR(
    user_address && network ? stakingInfo(user_address, network.chainID) : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { data: validatorStats } = useSWR(validatorStatsEndpoint, fetcher);
  const { data: lunaUSD } = useSWR(lunaUSDExRateEndpoint, fetcher);

  const getSetT1Validators = () => {
    setT1Validators("t1Validators", t1Validators(network.chainID));
  };

  const getSetProfile = async () => {
    if (!user_address) return;

    setMyProfile("profileState", Stage.LOADING);
    const profile: any = await getMyProfile(user_address);

    if (!profile) {
      setMyProfile("profileState", Stage.NO_PROFILE);
      setMyProfile("profile", {
        ...initialProfile,
        user_name: {
          public: true,
          value: `Anon ${getRandomName()}`,
        },
      });
      return;
    }

    setMyProfile("profile", {
      ...profile,
      user_name: {
        public: profile.user_name.public,
        value:
          profile.user_name.value !== ""
            ? profile.user_name.value
            : `Anon ${getRandomName()}`,
      },
    });
    setMyProfile("profileState", Stage.HAS_PROFILE);
  };

  const getSetDelegatedInfo = async () => {
    delegations.myDelegations = delegations.myDelegations.filter(
      (del: Delegation) => del.amountDelegated !== "0"
    );
    delegations.myDelegations.unshift(...t1Validators(network.chainID));

    for (let i = 0; i < delegations.myDelegations.length; i++) {
      t1ValidatorState.some((val, j) => {
        if (isT1Validator(val.validatorName, delegations.myDelegations[i])) {
          delegations.myDelegations[j] = delegations.myDelegations[i];
          delegations.myDelegations[i] = [];
        }
      });
    }

    setStakeInfo("delegatedInfo", {
      delegations: delegations.myDelegations.flat(),
      undelegations: delegations.undelegations.reverse(),
      validators: delegations.validators,
      totalUserDelegation: delegations.delegationTotal,
      totalRewards: parseInt(delegations.rewards.total),
    });
  };

  const resetBalances = () => {
    setBalance("uusd", 0);
    setBalance("uluna", 0);
  };

  const resetDelegatedInfo = () => {
    setStakeInfo("delegatedInfo", initialStakeState);
  };

  const resetProfile = () => {
    setMyProfile("profileState", Stage.NOT_CONNECTED);
    setMyProfile("profile", initialProfile);
  };

  useEffect(() => {
    if (user_address) {
      getSetT1Validators();
      getSetBalances(LCD, user_address);
      getSetProfile();
      window.location.pathname === "/" && history.push("/delegations");
    } else {
      resetBalances();
      resetProfile();
    }
  }, [user_address]);

  useEffect(() => {
    if (delegations) {
      getSetDelegatedInfo();
    } else {
      resetDelegatedInfo();
    }
  }, [delegations]);

  useEffect(() => {
    setRoute("route", location.pathname);
    document.querySelector("body")?.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!validatorStats) return;
    setValidatorStats("validatorStats", validatorStats);
  }, [validatorStats]);

  useEffect(() => {
    if (!lunaUSD) return;
    setExchageRate("uluna", lunaUSD.exchange_rate);
  }, [lunaUSD]);

  return <main>{children}</main>;
};

export default StateWrapper;