import { Grid, Stack, Text, Image, Box, Divider, Menu, Button, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CountUp from 'react-countup';
import Linker from "components/Snippets/Buttons/Linker";
import {
  useExchangeRate,
  useMyProfile,
  useStakeInfo,
  useValidatorStats,
} from "components/state/global";
import {
  GREY_1,
  GREY_4,
  RADIUS_L,
  RADIUS_M,
  SHADOW_MAIN,
  WHITE,
} from "constants/variables";
import { queryFinder } from "functions/queryFinder";
import { sliceAddress } from "functions/sliceAddress";
import useAddress from "hooks/useAddress";
import { useSetState } from "react-use";
import { useEffect, useState } from "react";
import TxModal from "components/Modal/TxModal";
import { claimRewardsMsg } from "functions/msgs";
import { MsgWithdrawDelegatorReward } from "@terra-money/terra.js";
import { toTerraAmount } from "functions/toXAmount";
import { t1Validators } from "constants/t1Validators";
import { useWallet } from "@terra-money/wallet-provider";
import { CountComponent } from "components/Snippets/Animations/CountComponent";

const returnDuration = ["YEARLY", "MONTHLY", "WEEKLY", "DAILY"];

const Overview = () => {
  const user_address = useAddress();
  const { network } = useWallet();
  const [profile] = useMyProfile("profile");
  const [validatorStats] = useValidatorStats("validatorStats");
  const [lunaExchangeRate] = useExchangeRate("uluna");
  const [{ totalUserDelegation, totalRewards, delegations }] =
    useStakeInfo("delegatedInfo");
  const uiTDA = toTerraAmount(totalUserDelegation);

  const [returns, setReturns] = useSetState({
    type: returnDuration[0],
    percentage: 0,
    luna_amount: 0,
    ust_amount: 0,
  });

  const [msgs, setMsgs] = useState<MsgWithdrawDelegatorReward[]>([]);

  const setClaimMsgs = () => {
    if (
      JSON.stringify(delegations) ===
      JSON.stringify(t1Validators(network.chainID))
    )
      return;

    const msgs = delegations.map((del) =>
      claimRewardsMsg({ user_address, validatorChosen: del.validatorAddress })
    );
    setMsgs(msgs.flat());
  };

  useEffect(() => {
    if (!returns?.type) return;

    const divider =
      returns.type === "YEARLY"
        ? 1
        : returns.type === "MONTHLY"
        ? 12
        : returns.type === "WEEKLY"
        ? 52
        : 365;
    const percentage =
      (validatorStats.annual_reward_percentage * 100) / divider;
    const luna_amount = toTerraAmount(
      (totalUserDelegation * validatorStats.annual_reward_percentage) / divider
    );
    const ust_amount = toTerraAmount(
      (totalUserDelegation *
        validatorStats.annual_reward_percentage *
        +lunaExchangeRate) /
        divider
    );

    setReturns({ percentage, luna_amount, ust_amount });
  }, [returns?.type, totalUserDelegation, validatorStats]);

  return (
    <>
      <TxModal msgs={msgs} />
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "1.1fr 1fr 1.1fr" }}
        p={{
          md: "35px 30px",
          sm: "35px 22px 10px 22px",
          xs: "35px 22px 10px 22px",
        }}
        columnGap="0"
        rowGap={{ md: "30px", sm: "20px", xs: "0px" }}
        border={`1px solid ${GREY_4}`}
        borderRadius={RADIUS_L}
        background={WHITE}
        boxShadow={SHADOW_MAIN}
      >
        <Stack
          w="100%"
          h="fit-content"
          direction="row"
          gap={{ md: "33px", sm: "25px", xs: "20px" }}
        >
          <Image
            src="/images/svg/ui-assets/user_icon.svg"
            alt="user"
            w={{ md: "60px", sm: "40px", xs: "40px" }}
            h={{ md: "60px", sm: "40px", xs: "40px" }}
          />
          <Stack
            direction="column"
            gap="15px"
            paddingBottom={{ md: "0", sm: "25px", xs: "25px" }}
          >
            <Box>
              <Text mb="10px" className="title_2">
                {profile.user_name.value ? profile.user_name.value : "..."}
              </Text>
              <Linker text="My Profile" to="/my-profile" />
            </Box>
            <a
              href={queryFinder(network.name, user_address)}
              target="_blank"
              className="special_1"
            >
              {sliceAddress(user_address, 6, -6)} ↗
            </a>
          </Stack>
        </Stack>
        <Stack
          w={{ md: "100%", sm: "80%", xs: "72.5%" }}
          h="fit-content"
          marginLeft={{ md: "0", sm: "68px", xs: "68px" }}
          padding={{ md: "5px 20px", sm: "25px 0", xs: "25px 0" }}
          borderLeft={{ md: `1px solid ${GREY_4}`, sm: "none", xs: "none" }}
          borderTop={{
            md: "none",
            sm: `1px solid ${GREY_4}`,
            xs: `1px solid ${GREY_4}`,
          }}
        >
          <Text className="body_2" mb="10px">
            Total Delegation
          </Text>
          <Text className="title_2" mb="10px">
            <CountComponent decimals={3} number={+uiTDA} />{" "}
            <span className="body_1">LUNA</span>
          </Text>
          <Text className="body_2">
            ≃ <CountComponent decimals={3} number={+uiTDA * lunaExchangeRate} />{" "}
            UST
          </Text>
        </Stack>
        <Stack
          w={{ md: "100%", sm: "80%", xs: "72.5%" }}
          h="fit-content"
          marginLeft={{ md: "0", sm: "68px", xs: "68px" }}
          padding={{ md: "5px 20px", sm: "25px 0", xs: "25px 0" }}
          borderLeft={{ md: `1px solid ${GREY_4}`, sm: "none", xs: "none" }}
          borderTop={{
            md: "none",
            sm: `1px solid ${GREY_4}`,
            xs: `1px solid ${GREY_4}`,
          }}
        >
          <Text className="body_2" mb="10px">
            Expected Staking Return
          </Text>
          <Stack
            direction="row"
            gap="5px"
            paddingBottom={{ md: "0", sm: "20px", xs: "15px" }}
          >
            <Text className="title_2" mb="5px">
              <CountComponent decimals={3} number={returns.percentage} />%
            </Text>
            <Divider orientation="vertical" color={GREY_4} />
            <Text
              className="title_2"
              display={{ md: "inline-block", sm: "flex", xs: "flex" }}
              flexDir={{ sm: "column", xs: "column" }}
              gap={{ md: "0", sm: "5px", xs: "5px" }}
            >
              <CountComponent decimals={3} number={returns.luna_amount} />{" "}
              <span className="body_1">LUNA</span>
            </Text>
          </Stack>
          <Menu matchWidth>
            <MenuButton
              width="fit-content"
              background="transparent"
              outline="none"
              boxShadow="none !important"
              className="title_2"
              fontSize="12px"
              lineHeight="18px"
              fontWeight="700"
              border={`1px solid ${GREY_4}`}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              {returns?.type} <span className="body_3">(APR)</span>
            </MenuButton>
            <MenuList>
              {returnDuration.map((rd, i) => (
                <MenuItem
                  key={i}
                  className="title_2"
                  fontSize="12px"
                  lineHeight="18px"
                  fontWeight="700"
                  onClick={() => setReturns({ type: rd })}
                >
                  {rd}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Stack>
        <Stack
          w="100%"
          h="fit-content"
          display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
        />
        <Stack
          w={{ md: "100%", sm: "80%", xs: "72.5%" }}
          h="fit-content"
          marginLeft={{ md: "0", sm: "68px", xs: "68px" }}
          padding={{ md: "5px 20px", sm: "25px 0", xs: "25px 0" }}
          borderLeft={{ md: `1px solid ${GREY_4}`, sm: "none", xs: "none" }}
          borderTop={{
            md: "none",
            sm: `1px solid ${GREY_4}`,
            xs: `1px solid ${GREY_4}`,
          }}
        >
          <Text className="body_2" mb="10px">
            Claimable Rewards
          </Text>
          <Text className="title_2" mb="5px">
            <CountComponent decimals={6} number={toTerraAmount(totalRewards)} />{" "}
            <span className="body_1">LUNA</span>
          </Text>
          <button
            onClick={setClaimMsgs}
            className="cta_2"
            style={{
              background: GREY_1,
              color: WHITE,
              borderRadius: RADIUS_M,
              outline: "none",
              boxShadow: "none !important",
              width: "fit-content",
              fontSize: "12px",
              lineHeight: "12px",
              padding: "14px 16px",
              marginTop: "20px",
            }}
          >
            CLAIM
          </button>
        </Stack>
        <Stack
          w="100%"
          h="fit-content"
          display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
        />
      </Grid>
    </>
  );
};

export default Overview;
