import { Link } from "react-router-dom";
import st_btn from "./Button.module.scss";

const Linker = ({
  text,
  to,
  onClick,
}: {
  text: string;
  to: string;
  onClick?: any;
}) => (
  <Link onClick={onClick ?? null} to={to} className={`${st_btn.linker}`}>
    {text}
    <img src="/images/svg/ui-assets/chevron_right.svg" />
  </Link>
);

export default Linker;
