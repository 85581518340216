import { Stage } from "constants/enums";
import { StakeState, t1Validators } from "constants/types";
import { initialProfile } from "pages/Profile/ProfileModules";
import { createGlobalState } from "react-hooks-global-state";

export const initialStakeState: StakeState = {
  delegations: [],
  undelegations: [],
  validators: [],
  totalUserDelegation: 0,
  totalRewards: 0,
}

export const initialT1Validators: t1Validators = {
  t1Validators: []
}

export const {
  setGlobalState: setValidatorStats,
  useGlobalState: useValidatorStats,
} = createGlobalState({
  validatorStats: {
    total_staking_assets: 0,
    annual_reward_percentage: 0,
    num_delegators: 0,
  },
});

export const { setGlobalState: setExchageRate, useGlobalState: useExchangeRate } =
  createGlobalState({
    uluna: 0,
});


export const { setGlobalState: setBalance, useGlobalState: useBalance } =
  createGlobalState({
    uusd: 0,
    uluna: 0,
});

export const { setGlobalState: setStakeInfo, useGlobalState: useStakeInfo } =
  createGlobalState({
    delegatedInfo: initialStakeState,
});

export const { setGlobalState: setMyProfile, useGlobalState: useMyProfile } =
  createGlobalState({
    profileState: Stage.NOT_CONNECTED,
    profile: initialProfile,
});

export const { setGlobalState: setRoute, useGlobalState: useRoute } =
  createGlobalState({
    route: window.location.pathname,
});

export const { setGlobalState: setT1Validators, useGlobalState: useT1Validators } =
  createGlobalState(initialT1Validators);
