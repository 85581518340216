import { Delegation } from "./types"

export const t1Validators = (chainID: String): Delegation[] => {
    if (chainID === "bombay-12") {
        return [
            {
                amountDelegated: "0",
                rewards: [],
                totalReward: "0",
                validatorAddress: process.env.REACT_APP_T1_VALIDATOR_OP_ADDRESS_BOMBAY!,
                validatorName: process.env.REACT_APP_T1_VALIDATOR_NAME_BOMBAY!,
                validatorStatus: "not_staked",
            }
        ]
    } else {
        return [
            {
                amountDelegated: "0",
                rewards: [],
                totalReward: "0",
                validatorAddress: process.env.REACT_APP_T1_VALIDATOR_OP_ADDRESS!,
                validatorName: process.env.REACT_APP_T1_VALIDATOR_NAME!,
                validatorStatus: "not_staked",
            }, 
            {
                amountDelegated: "0",
                rewards: [],
                totalReward: "0",
                validatorAddress: process.env.REACT_APP_P1_VALIDATOR_OP_ADDRESS!,
                validatorName: process.env.REACT_APP_P1_VALIDATOR_NAME!,
                validatorStatus: "not_staked",
            },
            {
                amountDelegated: "0",
                rewards: [],
                totalReward: "0",
                validatorAddress: process.env.REACT_APP_Z1_VALIDATOR_OP_ADDRESS!,
                validatorName: process.env.REACT_APP_Z1_VALIDATOR_NAME!,
                validatorStatus: "not_staked",
            }
        ]
    }
}