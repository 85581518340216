export const formatDate = (date: string) => {
    var d = new Date(date),
        month: string = '' + (d.getUTCMonth() + 1),
        day: string = '' + d.getUTCDate(),
        year: string = d.getUTCFullYear().toString().slice(2, 4),
        hour: string = '' + d.getUTCHours(),
        minute: string = '' + d.getUTCMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;

    return `${[year, month, day].join('/')} ${hour}:${minute} UTC`;
}