import { Center, Divider, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { CountComponent } from "components/Snippets/Animations/CountComponent";
import { useValidatorStats } from "components/state/global";
import { GREY_4, RADIUS_M, SHADOW_MAIN, WHITE } from "constants/variables";
import { toTerraAmount } from "functions/toXAmount";
import WindowDimensions from "hooks/useDimensions";
import React from "react";
import {v4 as uuidv4} from 'uuid';

const stats = [
  { title: "Total Staked Amount", value: "2120810", logo:"/staking.png", prefix: "$", separator: "," },
  { title: "Annual Yield", value: "12.6", logo:"/annual-reward.png", suffix: "%" },
  { title: "Delegators", value: "381", logo:"/delegators.png", noDecimal: true },
];

export const formatToCurrency = (amount: number) => {
  return toTerraAmount(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

const getStats = (stats: any[], isWeb: boolean) => {
  return stats.map((stat: any, index: number) => {
    return (
      <React.Fragment key={uuidv4()}>
        <Stack w={356} textAlign='center' justify='center' spacing={0} p={5}> 
          <Center>
            <Image src={stat.logo} width="50px" height="50px" mb={5}/>
          </Center>
          <Text className="body_1" fontSize={{base: 14, md: 18}}>{stat.title}</Text>
          <Text className="title_2" fontSize={{base: 18, md: 24}} fontWeight="bold">
            <CountComponent separator={stat.separator} decimals={stat.noDecimal ? 0 : 3} number={stat.value} prefix={stat.prefix} suffix={stat.suffix}/>
          </Text>
        </Stack>
        {index != stats.length - 1 && <Divider w={isWeb ? 0 : '90%'} h={isWeb ? 55 : 'auto'} className="grey_4" orientation={isWeb ? "vertical" : "horizontal"} bg={GREY_4}/>}        
      </React.Fragment>
    )
  });
};

const Stats = () => {
  const isWeb = WindowDimensions();
  const validatorStats = useValidatorStats("validatorStats");
  const statGrids = [
    {...stats[0], value: toTerraAmount(validatorStats?.[0]?.total_staking_assets).toFixed(2)},
    {...stats[1], value: (validatorStats?.[0]?.annual_reward_percentage * 100).toFixed(2)},
    {...stats[2], value: validatorStats?.[0]?.num_delegators},
  ];
  return (
    <Flex direction={{ base: 'column', md: 'row' }} mt={97} minH={120} boxShadow={SHADOW_MAIN} borderRadius={RADIUS_M} alignItems='center' bg={WHITE}>
      {getStats(statGrids, isWeb)}
    </Flex>
  )
};

export default Stats;

