import { Box, Flex, Heading, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GREY_6, ORANGE_5, RADIUS_M, SHADOW_MAIN, WHITE } from "constants/variables";
import WindowDimensions from "hooks/useDimensions";
import React from "react";

const transactions = [
  {datetime: "2022.04.17 15:28 AM", txHash: "401BB2E05351A77B885A1416847498B4F7F8FB5D5943077A9F21BDB4E120EE53", description: "Sent 9000 TONE to terra1gckw8uafym9y62eys45xxr4sfl8pe0f826g3ms"},
  {datetime: "2022.04.17 15:28 AM", txHash: "401BB2E05351A77B885A1416847498B4F7F8FB5D5943077A9F21BDB4E120EE53", description: "Sent 9000 TONE to terra1gckw8uafym9y62eys45xxr4sfl8pe0f826g3ms"},
  {datetime: "2022.04.17 15:28 AM", txHash: "401BB2E05351A77B885A1416847498B4F7F8FB5D5943077A9F21BDB4E120EE53", description: "Sent 9000 TONE to terra1gckw8uafym9y62eys45xxr4sfl8pe0f826g3ms"},
  {datetime: "2022.04.17 15:28 AM", txHash: "401BB2E05351A77B885A1416847498B4F7F8FB5D5943077A9F21BDB4E120EE53", description: "Sent 9000 TONE to terra1gckw8uafym9y62eys45xxr4sfl8pe0f826g3ms"},
];

const elipsis = (text: string, first: number = 5) => {
  return text.length > 30 ? text.substring(0, first) + '...' + text.substring(text.length - 4, text.length): text;
};

const Transactions = () => {
  const isWeb = WindowDimensions();
  return (
    <React.Fragment>
      <Flex paddingTop={{ md: "30px", sm: "30px", xs: "30px" }}>
        <Box p='2' className="title_3" color={ORANGE_5}>
          <Heading size='md'>Transaction List</Heading>
        </Box>
      </Flex>
      <TableContainer p={5} bg={WHITE} minH={220} borderRadius={RADIUS_M} boxShadow={SHADOW_MAIN} alignItems='center'>
        <Table variant='simple' size='lg'>
          <Thead bg={GREY_6}>
            <Tr>
              <Th className="title_5">DATE & TIME</Th>
              <Th className="title_5">TX HASH</Th>
              {isWeb && <Th className="title_5">DESCRIPTION</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((transaction, index) => (
              <Tr key={index}>
                <Td className="body_3" w='33%'>{transaction.datetime}</Td>
                <Td className="body_3" w='33%'><Link isExternal href={'https://terrasco.pe/mainnet/tx/' + transaction.txHash}>{elipsis(transaction.txHash)}</Link></Td>
                { isWeb && <Td className="body_3" w='33%'>{elipsis(transaction.description, 23)}</Td>}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default Transactions;