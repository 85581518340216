import { UserProfile, UserProfileSnippet } from "constants/types"

export const initialProfileTrait: UserProfileSnippet = {
    value: "",
    public: true,
}

export const initialProfile: UserProfile = {
    user_name: initialProfileTrait,
    email: initialProfileTrait,
    github: initialProfileTrait,
    twitter: initialProfileTrait,
    bio: initialProfileTrait
}