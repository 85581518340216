import InfoMolecule from "./InfoMolecule";
import st_pr from "../../Profile.module.scss";
import { UserProfile } from "constants/types";

const InfoMolecules = ({ profile }: { profile: UserProfile }): JSX.Element => {
  return (
    <div className={st_pr.info_molecules}>
      {/* <InfoMolecule title="Delegating Since" info="12-21-2021" />
      <InfoMolecule title="Delegator Rank" info="42" />
      <div className={st_pr.bufferer}/> */}
      <InfoMolecule title="Email" info={profile.email.value} />
      <InfoMolecule
        title="Github"
        info={profile.github.value ? `@${profile.github.value}` : ""}
      />
      <InfoMolecule
        title="Twitter"
        info={profile.twitter.value ? `@${profile.twitter.value}` : ""}
      />
    </div>
  );
};

export default InfoMolecules;
