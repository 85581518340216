import st_btn from "./Button.module.scss";

const BlackButton = ({ text, onClick }: 
    { text: string, onClick: any }): JSX.Element => (
    <button 
    onClick={ onClick }
    className={`cta_1 ${st_btn.black}`}>
        {text.toUpperCase()}
    </button>
)

export default BlackButton;