/* ----- Dashboard Page Enumerations ----- */
export enum Wanna {
    Stake = 0,
    Unstake = 1,
};
/* ----- Dashboard Page Enumerations ----- */

/* ----- ROUTE Enumerations ----- */
export enum Route {
  DELEGATIONS = "/delegations",
  TOKENS = "/tokens",
  NFTS = "/nfts",
  MY_PROFILE = "/my-profile",
}
/* ----- ROUTE Enumerations ----- */

/* ----- Profile Fetch Stage from Firebase ----- */
export enum Stage {
  NO_PROFILE = 0,
  HAS_PROFILE = 1,
  LOADING = 2,
  NOT_CONNECTED = 3,
}
/* ----- Profile Fetch Stage from Firebase ----- */