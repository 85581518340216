import { Box, Flex, Heading, Text, Stack, Divider } from "@chakra-ui/react";
import { CountComponent } from "components/Snippets/Animations/CountComponent";
import { ACCENT_1, GREY_4, ORANGE_5, RADIUS_M, SHADOW_MAIN, WHITE } from "constants/variables";
import WindowDimensions from "hooks/useDimensions";
import React, { useEffect } from "react";

const Performance = () => {
  const isWeb = WindowDimensions();
  const perf24Ref = React.useRef<any>(null);
  const perf7Ref = React.useRef<any>(null);

  useEffect(() => {
    const perf24 = perf24Ref.current?.innerText as string;
    if (perf24.trim().startsWith("+")) {
      perf24Ref.current.style.color = ACCENT_1;
    }
  }, [perf24Ref.current?.innerText]);

  useEffect(() => {
    const perf7 = perf7Ref.current?.innerText as string;
    if (perf7.trim().startsWith("-")) {
      perf7Ref.current.style.color = ORANGE_5;
    }
  }, [perf7Ref.current?.innerText]);

  return (
    <React.Fragment>
      <Flex paddingTop={{ md: "30px", sm: "30px", xs: "30px" }}>
        <Box p='2' className="title_3" color={ORANGE_5}>
          <Heading size='md'>Performance</Heading>
        </Box>
      </Flex>
      <Stack bg={WHITE} minH={220} borderRadius={RADIUS_M} boxShadow={SHADOW_MAIN} justify='center' alignItems='center' alignContent='center'>
        <Flex w='100%' direction={{ base: 'column', md: 'row' }} alignItems='center'>
          <Stack w='33%' direction='column' align='center' justify='center'>
            <Text className="body_1">24h%</Text>
            <Text className="title_2" ref={perf24Ref}>+3.6%</Text>
          </Stack>
          <Divider w={isWeb ? 0 : '90%'} h={isWeb ? 55 : 'auto'} className="grey_4" orientation={isWeb ? "vertical" : "horizontal"} bg={GREY_4}/>
          <Stack w='33%' direction='column' align='center' justify='center'>
            <Text className="body_1">7h%</Text>
            <Text className="title_2" ref={perf7Ref}>-8.6%</Text>
          </Stack>
          <Divider w={isWeb ? 0 : '90%'} h={isWeb ? 55 : 'auto'} className="grey_4" orientation={isWeb ? "vertical" : "horizontal"} bg={GREY_4}/>
          <Stack w='33%' direction='column' align='center' justify='center'>
            <Text className="body_1">Market Cap</Text>
            <Text className="title_2">
              <CountComponent separator={','} decimals={2} number={3800880} prefix={'$'}/>
            </Text>
          </Stack>
        </Flex>
        // TODO: Add chart when we have one.
      </Stack>
    </React.Fragment>
  )
};

export default Performance;