import {
  getChainOptions,
  useWallet,
  WalletProvider,
  WalletStatus,
} from "@terra-money/wallet-provider";
import Nav from "components/Nav/Nav";
import Footer from "components/Footer/Footer";
import Home from "pages/Home/Home";
import Dashboard from "pages/Dashboard/Dashboard";
import ProfilePage from "pages/Profile/ProfilePage";
import ReactDOM from "react-dom";
import { Box, ChakraProvider, extendTheme, ThemeConfig, Image } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import StateWrapper from "components/Wrappers/StateWrapper";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import "./styles/styles.scss";
import NFTs from "pages/NFTs/NFTPage";
import Privacy from "pages/Privacy/Privacy";
import Terms from "pages/Terms/Terms";
import Tokens from "pages/Tokens/Tokens";

const breakpoints = {
  xs: "320px",
  sm: "450px",
  md: "768px",
  lg: "960px",
  xl: "1440px",
  "2xl": "1536px",
};

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({ breakpoints, config });

const Loading = (): JSX.Element => {

  return (
    <Box 
    width="100%" height="calc(100vh - 75px)" display="grid" placeItems="center">
      <Image 
      className="loading_anim"
      borderRadius="50%"
      w="80px" h="80px"
      src="/images/svg/ui-assets/terran_one_orange_cube_transparent_background.svg"/>
    </Box>
  )
}

function App() {
  const queryClient = new QueryClient();
  const { status } = useWallet();

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Nav />
          <StateWrapper>
            <Switch>
              <Route exact path="/">
                {status === WalletStatus.INITIALIZING && <Loading/>}
                {status !== WalletStatus.INITIALIZING && <Home />}
              </Route>
              <Route exact path="/delegations">
                {status === WalletStatus.INITIALIZING && <Loading/>}
                {status === WalletStatus.WALLET_CONNECTED && <Dashboard />}
                {status === WalletStatus.WALLET_NOT_CONNECTED && (
                  <Redirect to="/" />
                )}
              </Route>
              <Route exact path="/nfts">
                {status === WalletStatus.INITIALIZING && <div>Loading...</div>}
                {status === WalletStatus.WALLET_CONNECTED && <NFTs />}
                {status === WalletStatus.WALLET_NOT_CONNECTED && (
                  <Redirect to="/" />
                )}
              </Route>
              <Route exact path="/my-profile">
                {status === WalletStatus.INITIALIZING && <Loading/>}
                {status === WalletStatus.WALLET_CONNECTED && <ProfilePage />}
                {status === WalletStatus.WALLET_NOT_CONNECTED && (
                  <Redirect to="/" />
                )}
              </Route>
              <Route exact path="/privacy">
                {status === WalletStatus.INITIALIZING && <Loading/>}
                {status === WalletStatus.WALLET_CONNECTED && <Privacy />}
                {status === WalletStatus.WALLET_NOT_CONNECTED && (
                  <Redirect to="/" />
                )}
              </Route>
              <Route exact path="/terms">
                {status === WalletStatus.INITIALIZING && <Loading/>}
                {status === WalletStatus.WALLET_CONNECTED && <Terms />}
                {status === WalletStatus.WALLET_NOT_CONNECTED && (
                  <Redirect to="/" />
                )}
              </Route>
              <Route exact path="/tokens">
                {status === WalletStatus.INITIALIZING && <Loading/>}
                {status === WalletStatus.WALLET_CONNECTED && <Tokens />}
                {status === WalletStatus.WALLET_NOT_CONNECTED && (
                  <Redirect to="/" />
                )}
              </Route>
            </Switch>
          </StateWrapper>
          <Footer />
        </Router>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <WalletProvider {...chainOptions}>
      <App />
    </WalletProvider>,
    document.getElementById("root")
  );
});
