import { useWallet, WalletStatus } from '@terra-money/wallet-provider'
import Connector from './Connector';
import ConnectorSatisfied from './ConnectorSatisfied';

import st_nav from "../Nav.module.scss";
import st_btn from "../../Snippets/Buttons/Button.module.scss";
import BlackButtonRound from 'components/Snippets/Buttons/BlackButtonRound';

const WalletProvider = () => {
    const { status } = useWallet();

    switch (status) {
        case WalletStatus.INITIALIZING:
            return <button className={`cta_2 ${st_btn.connect}`}>Loading...</button>
        case WalletStatus.WALLET_NOT_CONNECTED:
            return <Connector text="connect" style={undefined}/>;
        case WalletStatus.WALLET_CONNECTED:
            return <ConnectorSatisfied/>;
    }
}

export default WalletProvider;