import WalletProvider from "./WalletProvider/WalletProvider";
import st_nav from "./Nav.module.scss";
import { Link } from "react-router-dom";
import { useRoute } from "components/state/global";
import { Route, Stage } from "constants/enums";
import MobileNav from "./MobileNav";
import WindowDimensions from "hooks/useDimensions";
import { useEffect, useState } from "react";
import useAddress from "hooks/useAddress";

const Nav = (): JSX.Element => {
  const isWeb = WindowDimensions();
  const user_address = useAddress();
  const [route] = useRoute("route");
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const focused = {
    padding: "2.5px 0",
    borderBottom: "2.5px solid white",
  };

  return (
    <nav id={st_nav.nav}>
      <div className={st_nav.container}>
        {!hamburgerOpen ? (
          <div className={st_nav.left}>
            <Link to="/">
              <div className={st_nav.logo_title}>
                <img
                  className={st_nav.logo}
                  src="/images/svg/ui-assets/t1_cube_white_bg.svg"
                />
                {isWeb && (
                  <h3 className={`title_4 ${st_nav.title}`}>DELEGATOR</h3>
                )}
              </div>
            </Link>
            <ul>
              <li className="title_4">
                <Link
                  to="/delegations"
                  style={route === Route.DELEGATIONS ? focused : undefined}
                >
                  Delegations
                </Link>
              </li>
              <li className="title_4">
                <Link
                  to="/tokens"
                  style={route === Route.TOKENS ? focused : undefined}
                >
                  Tokens
                </Link>
              </li>
              <li className="title_4">
                <Link
                  to="/nfts"
                  style={route === Route.NFTS ? focused : undefined}
                >
                  NFTs
                </Link>
              </li>
              <li className="title_4">
                <Link
                  to="/my-profile"
                  style={route === Route.MY_PROFILE ? focused : undefined}
                >
                  My Profile
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div />
        )}
        <div className={st_nav.right}>
          {!hamburgerOpen && <WalletProvider />}
          {user_address && !isWeb && (
            <MobileNav toggleNavUI={() => setHamburgerOpen((prev) => !prev)} />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Nav;