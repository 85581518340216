import {
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  Image,
} from "@chakra-ui/react";
import BlackButton from "components/Snippets/Buttons/BlackButton";
import { setMyProfile, useMyProfile } from "components/state/global";
import { Stage } from "constants/enums";
import { EditProfileType } from "constants/types";
import { getRandomName } from "functions/getRandomName";
import { isValidEmail } from "functions/isValidEmail";
import useAddress from "hooks/useAddress";
import { initialProfileTrait } from "pages/Profile/ProfileModules";
import { useEffect, useState } from "react";
import { useSetState } from "react-use";
import { getMyProfile, saveMyProfile } from "services/firestore";
import { InputComponent, TextBoxComponent } from "./ModalComponents";

const ManipulateProfile = ({ open, close }: EditProfileType): JSX.Element => {
  const user_address = useAddress();
  const [myProfile] = useMyProfile("profile");
  const [profileState] = useMyProfile("profileState");
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [warning, setWarning] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const manipulate = profileState === Stage.NO_PROFILE ? "Create" : "Edit";

  const [profile, setProfile] = useSetState({
    user_name: initialProfileTrait,
    email: initialProfileTrait,
    github: initialProfileTrait,
    twitter: initialProfileTrait,
    bio: initialProfileTrait,
  });

  const changeUserName = (e: any) => {
    setProfile((prev) => {
      const new_name = {
        value: e.target.value,
        public: prev.user_name.public,
      };
      return { user_name: new_name };
    });
  };

  const changeUserNamePublic = (e: any) => {
    setProfile((prev) => {
      const new_name_public = {
        value: prev.user_name.value,
        public: e.target.checked,
      };
      return { user_name: new_name_public };
    });
  };

  const changeEmail = (e: any) => {
    if (isValidEmail(e.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }

    setProfile((prev) => {
      const new_email = {
        value: e.target.value,
        public: prev.email.public,
      };
      return { email: new_email };
    });
  };

  const changeEmailPublic = (e: any) => {
    setProfile((prev) => {
      const new_email_public = {
        value: prev.email.value,
        public: e.target.checked,
      };
      return { email: new_email_public };
    });
  };

  const changeGitHub = (e: any) => {
    setProfile((prev) => {
      const new_github = {
        value: e.target.value.replace("@", ""),
        public: prev.github.public,
      };
      return { github: new_github };
    });
  };

  const changeGitHubPublic = (e: any) => {
    setProfile((prev) => {
      const new_github_public = {
        value: prev.github.value,
        public: e.target.checked,
      };
      return { github: new_github_public };
    });
  };

  const changeTwitter = (e: any) => {
    setProfile((prev) => {
      const new_twitter = {
        value: e.target.value.replace("@", ""),
        public: prev.twitter.public,
      };
      return { twitter: new_twitter };
    });
  };

  const changeTwitterPublic = (e: any) => {
    setProfile((prev) => {
      const new_twitter_public = {
        value: prev.twitter.value,
        public: e.target.checked,
      };
      return { twitter: new_twitter_public };
    });
  };

  const changeBio = (e: any) => {
    setProfile((prev) => {
      const new_bio = {
        value: e.target.value,
        public: prev.bio.public,
      };
      return { bio: new_bio };
    });
  };

  const changeBioPublic = (e: any) => {
    setProfile((prev) => {
      const new_bio_public = {
        value: prev.bio.value,
        public: e.target.checked,
      };
      return { bio: new_bio_public };
    });
  };

  const saveProfile = async () => {
    if (!validEmail) {
      setWarning(true);
      return;
    }
    setSaving(true);
    await saveMyProfile(user_address, profile);
    resetModal();
  };

  const updateProfile = async () => {
    if (!user_address) return;

    const profile: any = await getMyProfile(user_address);

    if (profile) {
      setMyProfile("profile", {
        ...profile,
        user_name: {
          public: profile.user_name.public,
          value:
            profile.user_name.value !== ""
              ? profile.user_name.value
              : `Anon ${getRandomName()}`,
        },
      });
      setMyProfile("profileState", Stage.HAS_PROFILE);
    }
  };

  const resetProfile = async () => {
    if (!user_address) return;

    const profile: any = await getMyProfile(user_address);

    if (profile) {
      setMyProfile("profile", {
        ...profile,
        user_name: {
          public: profile.user_name.public,
          value:
            profile.user_name.value !== ""
              ? profile.user_name.value
              : myProfile.user_name.value,
        },
      });
      setMyProfile("profileState", Stage.HAS_PROFILE);
    }
  };

  const closeModal = () => {
    resetProfile();
    setValidEmail(true);
    setWarning(false);
    close();
    setSaving(false);
  };

  const resetModal = () => {
    updateProfile();
    setValidEmail(true);
    setWarning(false);
    close();
    setSaving(false);
  };

  useEffect(() => {
    profileState === Stage.HAS_PROFILE && setProfile(myProfile);
  }, [profileState, myProfile]);

  useEffect(() => {
    validEmail && setWarning(false);
  }, [validEmail]);

  return (
    <Modal
      isCentered
      autoFocus={false}
      isOpen={open}
      onClose={() => null}
      size="md"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent margin="15px">
        <ModalBody padding="30" maxHeight="700px">
          <Stack mt="3" direction="row" justifyContent="space-between">
            <Text className="title_2">{manipulate} Profile</Text>
            <Image
              src="/images/svg/ui-assets/modal_close.svg"
              cursor="pointer"
              onClick={closeModal}
            />
          </Stack>
          <Stack direction="column" gap="30">
            <InputComponent
              checked={profile.user_name.public}
              value={profile.user_name.value}
              onChange={changeUserName}
              toggle={changeUserNamePublic}
              title="User Name"
              placeHolder="Wagmiski"
            />
            <InputComponent
              isInvalid={warning}
              errorBorderColor="red.300"
              checked={profile.email.public}
              value={profile.email.value}
              onChange={changeEmail}
              toggle={changeEmailPublic}
              title="Email"
              placeHolder="snail@gmail.com"
            />
            <InputComponent
              checked={profile.github.public}
              value={profile.github.value}
              onChange={changeGitHub}
              toggle={changeGitHubPublic}
              title="Github Account"
              placeHolder="freewilly"
            />
            <InputComponent
              checked={profile.twitter.public}
              value={profile.twitter.value}
              onChange={changeTwitter}
              toggle={changeTwitterPublic}
              title="Twitter Account"
              placeHolder="stablekwon"
            />
            <TextBoxComponent
              checked={profile.bio.public}
              value={profile.bio.value}
              onChange={changeBio}
              toggle={changeBioPublic}
              title="Bio"
              placeHolder="My name is jeff"
            />
            <BlackButton
              text={
                profileState === Stage.NO_PROFILE
                  ? "Create"
                  : saving
                  ? "Saving..."
                  : "Save"
              }
              onClick={saveProfile}
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManipulateProfile;
