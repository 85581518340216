import st_btn from "./Button.module.scss";

const ConnectedButton = ({ text }: { text: string }): JSX.Element => (
  <span className={`title_4 ${st_btn.connected}`}>
    <img src="/images/svg/ui-assets/user_icon.svg" />
    {text}
    <img src="/images/svg/ui-assets/chevron_down.svg" />
  </span>
);

export default ConnectedButton;